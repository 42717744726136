import { useState, useEffect, useCallback } from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  formatDateISO,
} from 'utils/date';
const useItems = (categoryId = null, start, end) => {
  const { id: userId } = useSelector(state => state.user.profile);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchItems = useCallback(
    async (categoryId = null) => {
      if (!categoryId) {
        setItems([]);
        return [];
      }
      try {
        setLoading(true);
        const { data: items } = await api.get(
          `/items/${userId}/budget-detail`,
          {
            params: {
              start: formatDateISO(getFirstDayOfMonth(new Date(start))),
              end: formatDateISO(getLastDayOfMonth(new Date(end))),
              category_id: categoryId,
            },
          }
        );
        setItems(items);
      } catch (error) {
        console.error('Error fetching extract data:', error);
        toast.error('Ocorreu um erro ao buscar os dados dos items.');
      } finally {
        setLoading(false);
      }
    },
    [userId, start, end]
  );

  useEffect(() => {
    if (categoryId) {
      fetchItems(categoryId);
    }
  }, [start, end, categoryId, fetchItems]);

  const reload = useCallback(() => {
    fetchItems(categoryId);
    // eslint-disable-next-line
  }, [categoryId, start, end]);

  return {
    items,
    setItems,
    loading,
    setLoading,
    fetchItems,
    reloadItems: reload,
  };
};

const useItemsList = () => {
  const { id: userId } = useSelector(state => state.user.profile);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchItemsListByCategoryId = useCallback(
    async categoryId => {
      setLoading(true);

      if (!categoryId) {
        setLoading(false);
        return;
      }
      setItems([]);
      try {
        const { data: itemsData } = await api.get(
          `items/${userId}/category/${categoryId}`
        );
        setItems(itemsData);
      } catch (error) {
        console.error('Error fetching items:', error);
        toast.error('Ocorreu um erro ao buscar as items.');
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [userId]
  );

  return {
    items,
    loading,
    fetchItemsListByCategoryId,
  };
};

export { useItems, useItemsList };
