import React from 'react';
import PencilIcon from 'components/atoms/icons/PencilIcon';
import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';

export const TableReport = ({
  data,
  type,
  title,
  fetchItemValue,
  selectedItem,
  showModal,
  setShowModal,
  handleEditClick,
  canEdit = true
}) => {
  const firstHeader = [
    'Item',
    'Estimado'
  ]

  const lastHeader = [
    'Média',
    'Soma',
  ]

  return (
    <Card.Container className="spending-table">
      <Table.TitleSet nameTable={title} className="patrimony none" />
      <Card.Container className="table-recom ">
        <Table.Container>
          <Table.TableBasic className="first-table">
            <Table.Thead>
              <Table.Tr className={`receipt-table-title ${type}`}>
                {canEdit && <Table.Th className="budget-table-title first-table-border" />}
                {firstHeader.map((headerItem, index) => (
                  <Table.Th
                    key={Math.random()}
                    className={`budget-table-title ${index === 0 &&
                      'checkbox'}`}
                  >
                    {headerItem}
                  </Table.Th>
                ))}
                <Table.Th className="budget-table-title empty last-table-border" />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data.items?.map((column, rowIndex) => (
                <Table.Tr className="receipt-table-body" key={Math.random()}>
                  {canEdit && (
                    <Table.Td>
                      <PencilIcon
                        className="category-title"
                        onClick={() => handleEditClick(column)}
                      />
                    </Table.Td>
                  )}
                  <Table.Td
                    className={`receipt-table-body`}
                    key={Math.random()}
                  >
                    {column?.item_name}
                  </Table.Td>
                  <Table.Td
                    className={`receipt-table-body`}
                    key={Math.random()}
                  >
                    {column?.estimated}
                  </Table.Td>
                </Table.Tr>
              ))}
              <Table.Tr>
                <Table.Td className={` total-report blue-opacity`} />
                <Table.Td className="total-report blue-opacity first">
                  Total
                </Table.Td>
                <Table.Td className={`total-report center `}>
                  {data?.total_monthly_entries?.estimated}
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table.TableBasic>
        </Table.Container>
        <Table.Container>
          <Table.TableBasic className="second-table">
            <Table.Thead>
              <Table.Tr className={`receipt-table-title ${type}`}>
                {data?.total_monthly_entries?.monthly_data.map((item, index) => (
                  < Table.Th
                    className={`budget-table-title ${index === 0 &&
                      'first-table-border'}`}
                    key={Math.random()}
                  >
                    {item?.name}
                  </Table.Th>
                ))}
                <Table.Th className="budget-table-title empty last-table-border" />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data?.items?.map((column, rowIndex) => (
                <Table.Tr className="receipt-table-body" key={Math.random()}>
                  {column?.item_spendings_by_month?.monthly_data.map(
                    (item, index) => (
                      <Table.Td
                        className={`receipt-table-body`}
                        key={Math.random()}
                      >
                        {item?.value}
                      </Table.Td>
                    ))}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table.TableBasic>
        </Table.Container>
        <Table.Container>
          <Table.TableBasic className="third-table">
            <Table.Thead>
              <Table.Tr className={`receipt-table-title ${type}`}>
                {lastHeader.map((headerItem, index) => (
                  <Table.Th
                    key={Math.random()}
                    className={`budget-table-title ${index === 0 &&
                      'first-table-border'}`}
                  >
                    {headerItem}
                  </Table.Th>
                ))}
                <Table.Th className="budget-table-title empty last-table-border" />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data.items?.map((column, rowIndex) => (
                <Table.Tr className="receipt-table-body" key={Math.random()}>
                  <Table.Td
                    key={Math.random()}
                    className={`receipt-table-body`}
                  >
                    {column?.item_spendings_by_month?.average}
                  </Table.Td>
                  <Table.Td
                    className={`receipt-table-body`}
                    key={Math.random()}
                  >
                    {column?.item_spendings_by_month?.total}
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table.TableBasic>
        </Table.Container>
      </Card.Container>
    </Card.Container >
  );
};
