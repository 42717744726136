import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const getMonths = () => {
  return [
    { value: 'Janeiro', label: 'Janeiro' },
    { value: 'Fevereiro', label: 'Fevereiro' },
    { value: 'Março', label: 'Março' },
    { value: 'Abril', label: 'Abril' },
    { value: 'Maio', label: 'Maio' },
    { value: 'Junho', label: 'Junho' },
    { value: 'Julho', label: 'Julho' },
    { value: 'Agosto', label: 'Agosto' },
    { value: 'Setembro', label: 'Setembro' },
    { value: 'Outubro', label: 'Outubro' },
    { value: 'Novembro', label: 'Novembro' },
    { value: 'Dezembro', label: 'Dezembro' },
  ];
};

export const getFirstDayOfMonth = dateString => {
  const date = new Date(dateString);
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
};

export const getLastDayOfMonth = dateString => {
  const date = new Date(dateString);
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0));
};

export const formatDateISO = date => {
  return date.toISOString().split('T')[0];
};

export const getMonthsName = () => {
  return [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
};

export const formatDate = (date = new Date()) => {
  try {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${day < 10 ? '0' + day : day}/${
      month < 10 ? '0' + month : month
    }/${year}`;
  } catch (error) {
    return '';
  }
};

export const isDatableString = (dateString = '') => {
  if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
    return false;
  }

  const [day, month, year] = dateString.split('/').map(Number);

  if (day < 1 || day > 31 || month < 1 || month > 12 || year < 1) {
    return false;
  }

  const date = new Date(year, month - 1, day);
  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
};

export const stringToDate = (dateString = new Date()) => {
  if (!isDatableString(dateString)) {
    return;
  }

  const [day, month, year] = dateString.split('/').map(Number);

  const date = new Date(year, month - 1, day);

  if (
    date.getFullYear() !== year ||
    date.getMonth() !== month - 1 ||
    date.getDate() !== day
  ) {
    throw new Error('Data inválida');
  }

  return date;
};
export const getMonthsWithYears = (startYear, endYear) => {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const monthsWithYears = [];
  for (let year = startYear; year <= endYear; year++) {
    months.forEach(month => {
      monthsWithYears.push(`${month}, ${year}`);
    });
  }
  return monthsWithYears;
};

export const getYears = (startYear, endYear) => {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year.toString());
  }
  return years;
};

export const monthNumberToName = monthNumber => {
  const monthNames = {
    '1': 'Janeiro',
    '2': 'Fevereiro',
    '3': 'Março',
    '4': 'Abril',
    '5': 'Maio',
    '6': 'Junho',
    '7': 'Julho',
    '8': 'Agosto',
    '9': 'Setembro',
    '10': 'Outubro',
    '11': 'Novembro',
    '12': 'Dezembro',
  };

  return monthNames[monthNumber];
};

export const getMonthNumber = monthName => {
  const monthNames = {
    Janeiro: 1,
    Fevereiro: 2,
    Março: 3,
    Abril: 4,
    Maio: 5,
    Junho: 6,
    Julho: 7,
    Agosto: 8,
    Setembro: 9,
    Outubro: 10,
    Novembro: 11,
    Dezembro: 12,
  };

  return monthNames[monthName];
};

export const getCurrentMonthObject = baseDate => {
  let currentDate = new Date(
    Date.UTC(
      new Date(baseDate).getUTCFullYear(),
      new Date(baseDate).getUTCMonth(),
      new Date(baseDate).getUTCDate()
    )
  );

  let label = format(currentDate, 'MMMM, yyyy', { locale: ptBR });
  label = label.charAt(0).toUpperCase() + label.slice(1);
  const value = format(currentDate, 'yyyy-MM-dd');

  return { label, value };
};

export const calculateMonthDifference = (date1, date2) => {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);

  const yearDifference = secondDate.getFullYear() - firstDate.getFullYear();
  const monthDifference = secondDate.getMonth() - firstDate.getMonth();

  return yearDifference * 12 + monthDifference;
};
export const formatDateToISO = (date = new Date()) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  if (isNaN(date)) {
    console.error('Data inválida fornecida para formatDateToISO');
    return '';
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatDateToDisplay = date => {
  if (!date || typeof date !== 'string') {
    console.error('Formato de data inválido:', date);
    return '';
  }
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
};
