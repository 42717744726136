import React, { useState, useRef, useEffect } from 'react';
import DirectArrowDown from '../icons/DirectArrowDown';
import {
  DropdownOptionsList,
  DropdownOptionItem,
  DropdownContainer,
  DropdownTrigger,
  DropdownTriggerLabel,
  DropdownTriggerIcon,
  DropdownOptionItemLabel,
} from './styles';

export const Dropdown = ({
  getDropdownValue,
  setDropdownValue,
  options,
  placeholder = 'Selecione um tipo...',
  className,
}) => {
  const [selectedOption, setSelectedOption] = useState(placeholder);
  const [showOptions, setShowOptions] = useState(false);

  const handleToggleDropdown = () => {
    setShowOptions(prevState => !prevState);
  };

  const handleSelectOption = option => {
    setSelectedOption(option.label);
    if (setDropdownValue) {
      setDropdownValue({ label: option.label, value: option.value });
    } else {
      getDropdownValue(option.value);
    }
    handleToggleDropdown();
  };

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [showOptions]);

  useEffect(() => {
    setSelectedOption(placeholder);
  }, [placeholder]);

  return (
    <DropdownContainer>
      <DropdownTrigger
        onClick={handleToggleDropdown}
        className={`${className} ${showOptions ? 'hide-border' : ''}`}
        ref={modalRef}
      >
        <DropdownTriggerLabel className={className}>
          {selectedOption}
        </DropdownTriggerLabel>
        <DropdownTriggerIcon>
          <DirectArrowDown className={className} />
        </DropdownTriggerIcon>
      </DropdownTrigger>
      <DropdownOptionsList className={className} isOpen={showOptions}>
        {options.map((option, index) => (
          <DropdownOptionItem
            key={index}
            onClick={() => handleSelectOption(option)}
            className={`$className} ${placeholder === option.label ? 'selected' : ''}`}
          >
            <DropdownOptionItemLabel>{option.label}</DropdownOptionItemLabel>
          </DropdownOptionItem>
        ))}
      </DropdownOptionsList>
    </DropdownContainer>
  );
};
