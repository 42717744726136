import styled from 'styled-components';

export const GroupIcon = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  &.detailed-extract-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 52px;
    border-bottom: 1px solid ${props => props.theme.graySilver};
    padding: 20px 0;

    @media (max-width: 1440px) {
      height: 44px;
    }

    @media (max-width: 1024px) {
      width: 100%;
      justify-content: space-between;
      padding: 16px 0;
    }
  }
`;

export const IconContainer = styled.div`
  &.category {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;

    @media (max-width: 1440px) {
      width: 28px;
      height: 28px;
    }
  }

  &.category.spending {
    background-color: ${props => props.theme.detailedExtractItemSpending};
  }

  &.category.installment {
    background-color: ${props => props.theme.pinkInstallmentPurchases};
  }

  &.category.wallet {
    background-color: ${props => props.theme.blueHeaderWithSlowOpacity};
  }

  &.category.investment {
    background-color: ${props => props.theme.detailedInvestmentItemSpending};
  }
`;

export const TextContainer = styled.div`
  &.detailed-extract-item {
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    @media (max-width: 1440px) {
      height: 28px;
    }
  }

  &.detailed-extract-item.first {
    width: 7.29vw;
    margin-left: 10px;

    @media (max-width: 1250px) {
      width: 15vw;
    }
  }

  &.detailed-extract-item.third {
    margin-left: 0.57vw;
    width: 70px;
  }

  &.detailed-extract-item.fourth {
    width: 50px;
  }

  &.detailed-extract-item > .reload-icon {
    font-size: 14px;

    @media (max-width: 1440px) {
      font-size: 11px;
    }
  }
`;
