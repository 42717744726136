import React from 'react';
import GoIcon from 'components/atoms/icons/GoIcon';
import { useHistory } from 'react-router-dom';

export const NavigationCardRedirect = ({ to, children, className = '' }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(to);
  };

  return <GoIcon className={className} onClick={handleClick} />;
};
