import styled from 'styled-components';

export const Container = styled.div`
  &.goal-panel {
    width: 24%;
    height: 396px;
    margin-top: 32px;
    border-radius: 16px;
    transition: all 0.7s ease-in-out;

    @media (max-width: 1370px) {
      width: 48%;
    }
  }

  .financial-independence {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.generic-dream {
    display: flex;
    flex-direction: column;
    justify-content: end;
    background-image: linear-gradient(
        180deg,
        rgba(12, 5, 63, 0) -2.75%,
        rgba(12, 5, 63, 0.5525) 50.46%,
        rgba(12, 5, 63, 0.85) 99.89%
      ), url(${props => props.backgroundImage});
    background-size: cover;
    background-position: left;
    justify-content: end;
    padding: 0 38px 32px;
  }

  &.realized-generic-dream {
    display: flex;
    flex-direction: column;
    justify-content: end;
    background-image: linear-gradient(180deg, rgba(12, 5, 63, 0) 51.37%, rgba(12, 5, 63, 0.3) 83.78%, rgba(12, 5, 63, 0.75) 118.35%), url(${props => props.backgroundImage});
    background-size: cover;
    background-position: left;
    justify-content: end;
    padding: 0 38px 32px;
    text-align: center;
  }

  &.financial-independence {
    padding: 50px 39px;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(
        180deg,
        rgba(12, 5, 63, 0) -2.75%,
        rgba(12, 5, 63, 0.5525) 50.46%,
        rgba(12, 5, 63, 0.85) 99.89%
      ), url(${props => props.backgroundImage});
    background-size: cover;
    background-position: left;
  }

  &.empty-goal-panel {
    border: 1.5px dashed ${({ theme }) => theme.grayMedium};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:hover {
      transition: transform 0.3s ease-in-out;
      transform: scale(1.03) translateX(10px) translateY(10px);
      transform-origin: left end;
    }
  }

  &.dream-panel {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(
        180deg,
        rgba(12, 5, 63, 0) -2.75%,
        rgba(12, 5, 63, 0.5525) 37.69%,
        rgba(12, 5, 63, 0.85) 81.62%
      ),
      url(${props => props.backgroundImage});
    background-size: cover;
    background-position: left;
    justify-content: end;
    border-radius: 16px;
    width: 323px;
    height: 231px;
    padding-left: 13px;
    padding-right: 12px;
    padding-bottom: 18px;
    overflow: hidden;
  }

  &.clickable {
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  
  &.spaced {
    justify-content: space-between;
  }

  &.on-center {
    justify-content: center;
  }

  &.icons {
    width: max-content;
    height: 24px;
  }

  &.generic-dream-title {
    margin-bottom: 32px;

    @media (max-width: 1600px) {
      margin-bottom: 20px;
    }
  }

  &.column {
    flex-direction: column;
  }
`;