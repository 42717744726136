import React from 'react';
import { Header } from 'components/templates/Header';
import useFormatName from 'utils/user';
export const HomeHeader = () => {
  const { name, initials } = useFormatName();

  return (
    <Header.RootContainer className='home'>
      <Header.GroupItens className="client-side">
        <Header.Avatar
          className="avatar color-white background-blue-d2"
          loadingClassName="loading"
          text={initials}
        />
        <Header.Title
          text={`Olá, ${name}! Este é o seu Dashboard`}
          className="header apertura-regular blue-d2"
          loadingClassName="header-loading"
        />
      </Header.GroupItens>
      <Header.GroupItens className="input-side" loadingClassName="loading">
        <Header.Notification
          className={'header icon-color-gray-4 icon-color-gray-4'}
          loadingClassName="invisible"
        />
        <Header.SearchBar className="header" placeholder="Pesquisar..." />
      </Header.GroupItens>
    </Header.RootContainer>
  );
};
