import React, { useState, useRef, useEffect } from 'react';
import {
  ArrowContainer,
  Container,
  DropdownContent,
  Month,
  ChevronIcon,
} from './styles';
import { FaChevronDown } from 'react-icons/fa';
import light from 'styles/themes/light';
import { Span } from 'components/atoms/Span';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import {
  setYear,
  setMonth,
  parseISO,
  isSameMonth,
  isValid,
} from 'date-fns';
import {
  getCurrentMonthObject,
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from 'utils/date';

export const DateDropdown = ({
  className = '',
  prefix = '',
  selectedDate = {
    value: 'yyyy-MM-dd',
    label: 'dd/MM/yyyy',
  },
  setSelectedDate = () => {},
  getLastDay = false,
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(() => {
    const initialDate = parseISO(selectedDate.value);
    return isValid(initialDate)
      ? initialDate.getFullYear()
      : new Date().getFullYear();
  });

  const monthsName = [
    'JAN',
    'FEV',
    'MAR',
    'ABR',
    'MAI',
    'JUN',
    'JUL',
    'AGO',
    'SET',
    'OUT',
    'NOV',
    'DEZ',
  ];

  const getMonthDate = index => {
    const baseDate = setYear(new Date(), currentYear);
    const monthDate = getLastDay
      ? getLastDayOfMonth(setMonth(baseDate, index))
      : getFirstDayOfMonth(setMonth(baseDate, index + 1));
    return monthDate;
  };

  const getMonthName = index => {
    const date = getMonthDate(index);
    setSelectedDate(getCurrentMonthObject(date));
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const addNewYear = () => {
    setCurrentYear(currentYear + 1);
  };

  const removeNewYear = () => {
    setCurrentYear(currentYear - 1);
  };

  useEffect(() => {
    const initialDate = parseISO(selectedDate.value);
    if (isValid(initialDate)) {
      setCurrentYear(initialDate.getFullYear());
    }
  }, [selectedDate]);

  return (
    <Container className={`${className} dropdown`} onClick={toggleDropdown}>
      <Span className={`${className} dropdown`}>
        {prefix || ''} {selectedDate.label || ''}
      </Span>

      <ChevronIcon className={`${className}`} isRotated={isOpen}>
        <FaChevronDown
          color={light.blueMidnight}
          className="date-dropdown-arrow"
        />
      </ChevronIcon>

      {isOpen && (
        <>
          <DropdownContent ref={dropdownRef} className={`${className} title`}>
            <ArrowContainer
              className={`${className}`}
              onClick={e => {
                e.stopPropagation();
                removeNewYear();
              }}
            >
              <IoIosArrowBack className="date-dropdown-arrow" />
            </ArrowContainer>
            <Span className={`${className} date-dropdown-year`}>
              {currentYear}
            </Span>
            <ArrowContainer
              className={`${className}`}
              onClick={e => {
                e.stopPropagation();
                addNewYear();
              }}
            >
              <IoIosArrowForward className="date-dropdown-arrow" />
            </ArrowContainer>
          </DropdownContent>
          <DropdownContent ref={dropdownRef} className={`${className} body`}>
            {monthsName.map((month, index) => {
              const monthDate = getMonthDate(index);
              const isSelected = isSameMonth(
                parseISO(selectedDate.value),
                monthDate
              );
              const classname = isSelected ? 'selected' : '';
              return (
                <Month
                  onClick={() => getMonthName(index)}
                  key={index}
                  className={`${className} ${classname}`}
                >
                  <Span className={`${className} date-dropdown-month-name`}>
                    {month}
                  </Span>
                </Month>
              );
            })}
          </DropdownContent>
        </>
      )}
    </Container>
  );
};
