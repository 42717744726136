import React from 'react';
import { Container, IconContainer, TextContainer, GroupIcon } from './styles';
import { Span } from 'components/atoms/Span';
import PencilIcon from 'components/atoms/icons/PencilIcon';
import { getCategoryIconByClassification } from 'components/organisms/CategoryIntroduction/utils';
import {
  buildEntry,
  buildInstallment,
  buildInvestment,
} from 'services/builders';
import { RecurrentIcon } from 'components/atoms/icons/RecurrentIcon';

export const DetailedItem = ({
  categoryClassification = '',
  className = '',
  categoryName = '',
  categoryItemName = '',
  releaseDate = 'dd/mm/aa',
  value = '',
  installments = '',
  paymentMethod = '',
  type = '',
  editable = true,
  setShowEditItemModal = () => {},
  setItemData = () => {},
  itemData,
}) => {
  const getColorBase = (type, categoryClassification) => {
    const normalizedCategoryClassification = categoryClassification.toLowerCase();
    const normalizedType = type.toLowerCase();
    if (
      ['à vista', 'recorrente'].includes(normalizedType) &&
      !categoryClassification
    ) {
      return 'spending';
    }
    if (normalizedType === 'receipts') {
      return 'wallet';
    }
    if (normalizedCategoryClassification === 'investimentos') {
      return 'investment';
    }
    if (normalizedType === 'parcelado') {
      return 'installment';
    }

    return 'spending';
  };

  const handleIconClick = () => {
    if (!editable) return;
    let buildedData = {};
    if (categoryClassification === 'Investimentos') {
      buildedData = buildInvestment(itemData);
      setItemData(buildedData);
      setShowEditItemModal(prev => !prev);
      return;
    }
    if (itemData.type === 'Parcelado') {
      buildedData = buildInstallment(itemData);
      setItemData(buildedData);
      setShowEditItemModal(prev => !prev);
      return;
    } else {
      buildedData = buildEntry(itemData, true);
      setItemData(buildedData);
      setShowEditItemModal(prev => !prev);
    }
  };

  const colorBase = getColorBase(type, categoryClassification);

  const getPaymentMethodRow = (name, installments, type) => {
    return type === 'Recorrente' ? (
      <RecurrentIcon />
    ) : (
      <Span className={`${className} body payment-title`}>{installments}</Span>
    );
  };

  const getPaymentMethodTitle = paymentMethod => {
    const paymentTitles = {
      Parcelas: (
        <Span className={`${className} title installments-header`}>
          {paymentMethod}
        </Span>
      ),
    };

    return (
      paymentTitles[paymentMethod] || (
        <Span className={`${className} title payment-title`}>
          {paymentMethod}
        </Span>
      )
    );
  };

  return (
    <Container className={className}>
      <GroupIcon>
        <IconContainer className={`${className} category ${colorBase}`}>
          {getCategoryIconByClassification(
            categoryClassification,
            categoryName,
            true
          )}
        </IconContainer>
        <TextContainer className={`${className} first`}>
          <Span className={`${className} title`}>{categoryName}</Span>
          <Span className={`${className} body`}>{categoryItemName}</Span>
        </TextContainer>
      </GroupIcon>
      <TextContainer className={`${className} second`}>
        <Span className={`${className} title`}>Lançamento</Span>
        <Span className={`${className} body`}>{releaseDate}</Span>
      </TextContainer>
      <TextContainer className={`${className} third`}>
        <Span className={`${className} title`}>Valor</Span>
        <Span className={`${className} body`}>{value}</Span>
      </TextContainer>
      <TextContainer className={`${className} fourth`}>
        {getPaymentMethodTitle(paymentMethod, installments)}
        {getPaymentMethodRow(paymentMethod, installments, type)}
      </TextContainer>
      <PencilIcon
        className={`${className} ${!editable ? 'disable' : ''}`}
        onClick={handleIconClick}
      />
    </Container>
  );
};
