import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import api from 'services/api';
import toast from 'react-hot-toast';
import { installmentValidationSchema } from 'utils/validator';
const useUpdateInstallment = () => {
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const { id: userId } = useSelector(state => state.user.profile);

  const updateInstallment = useCallback(
    async (installmentId, updatedData, setShowEditItemModal) => {
      if (!installmentId) {
        toast.error('ID do installment inválido.');
        return null;
      }

      const validate = await installmentValidationSchema(updatedData);

      if (!validate.success) {
        validate.error.forEach(message => {
          toast.error(message.message);
        });
        return;
      }

      setUpdating(true);
      setError(null);
      const updatePromise = api
        .put(`/installments/${installmentId}`, updatedData)
        .then(response => {
          setShowEditItemModal(false);
          return response.data;
        })
        .catch(err => {
          console.error('Erro ao atualizar o item:', err);
          setError(err);
          throw err;
        })
        .finally(() => {
          setUpdating(false);
        });

      await toast.promise(updatePromise, {
        loading: 'Atualizando item...',
        success: <b>Item atualizado com sucesso!</b>,
        error: <b>Erro ao atualizar o item.</b>,
      });

      return updatePromise;
    },
    // eslint-disable-next-line
    [userId]
  );

  return { updateInstallment, updating, error };
};

const useDeleteInstallment = () => {
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(null);
  const { id: userId } = useSelector(state => state.user.profile);

  const deleteInstallment = useCallback(
    async (installmentId, setShowDeleteConfirmModal) => {
      if (!installmentId) {
        toast.error('ID do installment inválido.');
        return null;
      }
      setDeleting(true);
      setError(null);

      const deletePromise = api
        .delete(`/installments/${installmentId}`)
        .then(response => {
          setShowDeleteConfirmModal(false);
          return response.data;
        })
        .catch(err => {
          console.error('Erro ao deletar o item:', err);
          setError(err);
          throw err;
        })
        .finally(() => {
          setDeleting(false);
        });

      await toast.promise(deletePromise, {
        loading: 'Deletando item...',
        success: <b>Item deletado com sucesso!</b>,
        error: <b>Erro ao deletar o item.</b>,
      });

      return deletePromise;
    },
    // eslint-disable-next-line
    [userId]
  );

  return { deleteInstallment, deleting, error };
};

export { useUpdateInstallment, useDeleteInstallment };
