import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React from 'react';

export const CommittedMonthlyTable = ({
  committedMonthly,
  currentDate,
  setCurrentDate,
  updateRecorrenceItem,
  onChangeDate,
}) => {
  const columnOrder = [
    'item_name',
    'category_name',
    'when',
    'estimated',
    'accomplished',
    'recurrent',
    'status',
  ];

  const header = [
    'Item',
    'Categoria',
    'Vencimento',
    'Estimado',
    'Realizado',
    'Recorrente',
    'Status',
  ];

  const getClassByStatus = status => {
    const statusClasses = {
      ATRASADO: 'red',
      'A PAGAR': 'blue-lilac',
      PAGO: 'blue-midnight',
    };

    return statusClasses[status] || '';
  };

  return (
    <Card.Container className="spending-table">
      <Table.Title
        nameTable="Mensal Comprometido"
        type="month"
        className="budget month"
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        tooltipText="Acontece todo mês e tem data específica para pagar"
        onChangeDate={onChangeDate}
      />
      <Table.Overflow>
        <Table.TableBasic className="receipt-table">
          <Table.Thead>
            <Table.Tr className="receipt-table-title spendings">
              {header.map((item, index) => (
                <Table.Th
                  className={`budget-table-title ${index === 0 &&
                    'first-table-border checkbox'}`}
                  first={index === 0}
                  key={index}
                >
                  {item}
                </Table.Th>
              ))}
              <Table.Th className="budget-table-title empty last-table-border" />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {committedMonthly.map((column, rowIndex) => (
              <Table.Tr className="receipt-table-body" key={rowIndex}>
                {columnOrder.map((item, index) => {
                  let currentItem = column[item];

                  if (item === 'recurrent') {
                    return (
                      <Table.Td
                        className="receipt-table-body recurrent"
                        key={index}
                      >
                        <Table.Switch
                          checked={currentItem}
                          onClick={() =>
                            updateRecorrenceItem({
                              id: column.item_id,
                              recurrent: !column.recurrent,
                            })
                          }
                          getValue={() => {}}
                          className="receipt-table-body recurrent"
                        />
                      </Table.Td>
                    );
                  }
                  if (item === 'status') {
                    return (
                      <Table.Td
                        className={`receipt-table-body status ${getClassByStatus(
                          currentItem
                        )}`}
                        key={index}
                      >
                        {currentItem}
                      </Table.Td>
                    );
                  }

                  return (
                    <Table.Td
                      className={`receipt-table-body ${item === 'item' &&
                        'first-table-border checkbox'} ${index === 0 &&
                        'first'}`}
                      first={item === 'item'}
                      key={index}
                    >
                      {currentItem}
                    </Table.Td>
                  );
                })}
                <Table.Td className="receipt-table-body"></Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.TableBasic>
      </Table.Overflow>
    </Card.Container>
  );
};
