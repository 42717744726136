import React, { useState, useEffect, useCallback } from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  formatDateISO,
} from 'utils/date';
import useDebounce from './useDebounce';
import { investmentValidationSchema } from 'utils/validator';

const useInvestmentsItems = (categoryId = null, start, end) => {
  const { id: userId } = useSelector(state => state.user.profile);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchItems = useCallback(
    async (categoryId = null) => {
      if (!categoryId) {
        setItems([]);
        return [];
      }
      try {
        setLoading(true);
        const { data: items } = await api.get(`investments/item/${userId}`, {
          params: {
            start: formatDateISO(getFirstDayOfMonth(new Date(start))),
            end: formatDateISO(getLastDayOfMonth(new Date(end))),
            category_id: categoryId,
          },
        });
        setItems(items);
      } catch (error) {
        console.error('Error fetching extract data:', error);
        toast.error('Ocorreu um erro ao buscar os dados dos items.');
      } finally {
        setLoading(false);
      }
    },
    [userId, start, end]
  );

  useEffect(() => {
    if (categoryId) {
      fetchItems(categoryId);
    }
  }, [start, end, categoryId, fetchItems]);

  const reload = useCallback(() => {
    fetchItems(categoryId);
    // eslint-disable-next-line
  }, [categoryId, start, end]);

  return {
    items,
    setItems,
    loading,
    setLoading,
    fetchItems,
    reloadItems: reload,
  };
};
const useInvestments = (start = new Date(), end = new Date()) => {
  const { id: userId } = useSelector(state => state.user.profile);
  const [investments, setInvestments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(async () => {
    let parsedStart = getFirstDayOfMonth(new Date(start));
    let parsedEnd = getLastDayOfMonth(new Date(end));

    parsedStart = formatDateISO(parsedStart);
    parsedEnd = formatDateISO(parsedEnd);
    setLoading(true);
    setInvestments([]);
    try {
      const { data: investmentsCategoriesData } = await api.get(
        `investments/category/${userId}`,
        {
          params: {
            start: parsedStart,
            end: parsedEnd,
          },
        }
      );
      setTotal(investmentsCategoriesData?.totalValue?.formatted);

      setInvestments(investmentsCategoriesData.categories);
    } catch (error) {
      console.error('Error fetching extract data:', error);
      toast.error('Ocorreu um erro ao buscar os dados do extrato.');
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [userId, start, end]);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [fetchData, userId]);

  return {
    categories: investments,
    total,
    loading,
    fetchData,
  };
};
const useFetchInvestmentsEntries = (itemId = null, start, end) => {
  const [investments, setInvestments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id: userId } = useSelector(state => state.user.profile);

  const fetchInvestments = useCallback(
    async (itemId = null) => {
      if (!itemId) {
        return [];
      }
      setLoading(true);
      setError(null);

      try {
        const { data: fetchedInvestments } = await api.get(
          `investments/${userId}`,
          {
            params: {
              start: formatDateISO(getFirstDayOfMonth(new Date(start))),
              end: formatDateISO(getLastDayOfMonth(new Date(end))),
              item_id: itemId,
            },
          }
        );
        setInvestments(fetchedInvestments);
        return fetchedInvestments;
      } catch (err) {
        console.error('Error fetching entries:', err);
        setError(err);
        toast.error('Ocorreu um erro ao buscar os dados dos items.');
        return [];
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [userId, start, end, itemId]
  );

  useEffect(
    () => {
      if (itemId) {
        fetchInvestments(itemId);
      }
    },
    // eslint-disable-next-line
    [start, end, itemId]
  );

  const reload = useCallback(() => {
    fetchInvestments(itemId);
    // eslint-disable-next-line
  }, [itemId, start, end]);
  return {
    entries: investments,
    loading,
    error,
    fetchEntries: fetchInvestments,
    reload,
  };
};

const useFetchInvestmentsByCategory = (
  initialCategoryType = null,
  start = new Date(),
  end = new Date(),
  inputSearch = ''
) => {
  const [investments, setInvestments] = useState([]);
  const [loadings, setLoadings] = useState({
    loadingInvestments: false,
    loadingInvestmentsMore: false,
  });
  const [error, setError] = useState(null);
  const { id: userId } = useSelector(state => state.user.profile);
  const [page, setPage] = useState(1);
  const [totalInvestmentsSearch, setTotalInvestmentsSearch] = useState(0);

  const debouncedInputSearch = useDebounce(inputSearch, 500);

  const updateLoadingState = useCallback(isLoadingMore => {
    setLoadings({
      loadingInvestments: !isLoadingMore,
      loadingInvestmentsMore: isLoadingMore,
    });
  }, []);

  const getApiParams = useCallback(
    (categoryType, startDate, endDate) => ({
      start: formatDateISO(getFirstDayOfMonth(new Date(startDate))),
      end: formatDateISO(getLastDayOfMonth(new Date(endDate))),
      category_type: categoryType,
      limit: 10,
      page,
      inputSearch: debouncedInputSearch,
      detailed: true,
    }),
    [debouncedInputSearch, page]
  );

  const handleApiError = useCallback(err => {
    console.error('Error fetching investimentos:', err);
    setError(err);
    toast.error('Ocorreu um erro ao buscar os dados dos items.');
  }, []);

  const fetchInvestments = useCallback(
    async (
      categoryType = initialCategoryType,
      startDate = start,
      endDate = end,
      resetPage = false
    ) => {
      if (!categoryType) {
        return [];
      }

      const isLoadingMore = resetPage ? false : true;
      updateLoadingState(isLoadingMore);
      setError(null);
      try {
        const { data: fetchedInvestments } = await api.get(
          `investments/${userId}`,
          {
            params: getApiParams(categoryType, startDate, endDate),
          }
        );
        setInvestments(prevInvestments =>
          resetPage
            ? fetchedInvestments.entries
            : [...prevInvestments, ...fetchedInvestments.entries]
        );
        const totalValue = fetchedInvestments.total?.formatted ?? null;
        setTotalInvestmentsSearch(totalValue);

        return fetchedInvestments;
      } catch (err) {
        handleApiError(err);
        return [];
      } finally {
        setLoadings({
          loadingInvestments: false,
          loadingInvestmentsMore: false,
        });
      }
    },
    // eslint-disable-next-line
    [
      userId,
      initialCategoryType,
      start,
      end,
      getApiParams,
      updateLoadingState,
      handleApiError,
      debouncedInputSearch,
    ]
  );

  const reloadEntries = useCallback(() => {
    setPage(1);
    setInvestments([]);
    fetchInvestments(initialCategoryType, start, end, true);
  }, [fetchInvestments, initialCategoryType, start, end]);

  useEffect(
    () => {
      if (debouncedInputSearch.length > 0) {
        setInvestments([]);
        setPage(1);
      }
      fetchInvestments(initialCategoryType, start, end, page === 1);
    },
    // eslint-disable-next-line
    [
      page,
      start,
      end,
      debouncedInputSearch,
    ]
  );
  return {
    entries: investments,
    loadings,
    error,
    fetchEntries: fetchInvestments,
    page,
    setPage,
    isSearchActive: debouncedInputSearch.length > 0,
    totalEntriesSearch: totalInvestmentsSearch,
    reloadEntries,
  };
};

const useUpdateInvestment = () => {
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const { id: userId } = useSelector(state => state.user.profile);

  const updateInvestment = useCallback(
    async (investmentId, updatedData, setShowEditItemModal) => {
      if (!investmentId) {
        toast.error('ID do investimento inválido.');
        return null;
      }
      const validate = await investmentValidationSchema(updatedData);

      if (!validate.success) {
        validate.error.forEach(message => {
          toast.error(message.message);
        });
        return;
      }
      setUpdating(true);
      setError(null);
      const updatePromise = api
        .put(`investments/${investmentId}`, updatedData)
        .then(response => {
          setShowEditItemModal(false);
          return response.data;
        })
        .catch(err => {
          console.error('Erro ao atualizar o item:', err);
          setError(err);
          throw err;
        })
        .finally(() => {
          setUpdating(false);
        });

      await toast.promise(updatePromise, {
        loading: 'Atualizando item...',
        success: <b>Item atualizado com sucesso!</b>,
        error: <b>Erro ao atualizar o item.</b>,
      });

      return updatePromise;
    },
    // eslint-disable-next-line
    [userId]
  );

  return { updateInvestment, updating, error };
};

const useDeleteInvestment = () => {
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(null);

  const deleteInvestment = useCallback(async (investmentId, onSuccess) => {
    if (!investmentId) {
      toast.error('ID do item inválido.');
      return null;
    }

    setDeleting(true);
    setError(null);

    const deletePromise = api
      .delete(`investments/${investmentId}`)
      .then(response => {
        if (onSuccess) {
          onSuccess();
        }
        return response.data;
      })
      .catch(err => {
        console.error('Erro ao deletar o item:', err);
        setError(err);
        throw err;
      })
      .finally(() => {
        setDeleting(false);
      });

    await toast.promise(deletePromise, {
      loading: 'Deletando item...',
      success: <b>Item deletado com sucesso!</b>,
      error: <b>Erro ao deletar o item.</b>,
    });

    return deletePromise;
  }, []);

  return { deleteInvestment, deleting, error };
};

export {
  useInvestments,
  useInvestmentsItems,
  useFetchInvestmentsEntries,
  useFetchInvestmentsByCategory,
  useUpdateInvestment,
  useDeleteInvestment,
};
