import React, { useCallback } from 'react';
import { Page } from 'components/templates/Page';
import { GoalsPanelContainer } from './styles';
import { GoalPanelHeader } from 'components/molecules/GoalPanelHeader';
import { Goal } from 'components/molecules/Goal';
import { GenericLoading } from 'components/atoms/GenericLoading';
import useGoals from 'hooks/useGoals';
import toast from 'react-hot-toast';

export default function GoalPanel() {
  const {
    goals,
    loading,
    setLoading,
    card,
    fetchGoals,
    updateGoal,
    createGoal,
    deleteGoal,
    financialBalance,
    patrimonialBalance,
    fetchBalances,
    uploadFile,
  } = useGoals();

  const reloadPage = useCallback(async () => {
    setLoading(true);
    const [goals, balances] = await Promise.all([
      fetchGoals(),
      fetchBalances()
    ])

    if (!goals || !balances) {
      toast.error('Não foi possível carregar os dados da página!');
    }

    setLoading(false);
  }, [fetchGoals, fetchBalances, setLoading]);

  const handleToggleDreamStatus = useCallback(
    async (goalId, doneAt) => {
      try {
        setLoading(true);
        const updatedData = { done_at: doneAt ? null : new Date() };
        await updateGoal(goalId, updatedData);
        await reloadPage();
        toast.success('Sonho realizado!');
        return true;
      } catch (error) {
        toast.error('Não foi possível atualizar o status do sonho')
        return false;
      } finally {
        setLoading(false);
      }
    },
    [updateGoal, reloadPage, setLoading]
  );
  const handleCreateGoal = async goalData => {
    try {
      setLoading(true);
      const response = await createGoal(goalData);
      await reloadPage();
      toast.success('Sonho criado!');
      return response;
    } catch (error) {
      toast.error('Não foi possível criar o sonho')
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateGoal = async (goalId, goalData) => {
    try {
      setLoading(true);
      const response = await updateGoal(goalId, goalData);
      await reloadPage();
      toast.success('Sonho atualizado!');
      return response;
    } catch (error) {
      toast.error('Não foi possível atualizar o sonho')
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteGoal = async goalId => {
    try {
      setLoading(true);
      await deleteGoal(goalId);
      await reloadPage();
      toast.success('Sonho deletado!');
    } catch (error) {
      toast.error('Não foi possível deletar o sonho')
      return false;
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    reloadPage();
  }, [reloadPage]);

  const financialIndependeceGoal = goals.find(
    goal => goal.type === 'financial-independence'
  );
  const anotherGoals = goals.filter(
    goal => goal.type !== 'financial-independence'
  )

  const pageContent = loading ? (
    <Page.RootContainer className='full'>
      <GoalPanelHeader loading={loading} />
      <GoalsPanelContainer className="loading">
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <GenericLoading className="goals-panel-dream" key={index} />
          ))}
      </GoalsPanelContainer>
    </Page.RootContainer>
  ) : (
    < Page.RootContainer className='full' >
      <GoalPanelHeader
        financialPatrimony={financialBalance.formatted}
        materialPatrimony={patrimonialBalance.formatted}
        seleable={card.sealed_value.formatted}
        investmentValue={card.investments_value.formatted}
        loading={loading}
      />
      <GoalsPanelContainer>
        <Goal
          type="financial-independence"
          dreamProps={financialIndependeceGoal}
          onToggleDreamStatus={handleToggleDreamStatus}
          reloadPage={reloadPage}
          onCreateGoal={handleCreateGoal}
          onUpdateGoal={handleUpdateGoal}
          onDeleteGoal={handleDeleteGoal}
          uploadFile={uploadFile}
        />
        {anotherGoals.map((goal, index) => (
          <Goal
            key={index}
            type={goal.type}
            dreamProps={goal}
            onToggleDreamStatus={handleToggleDreamStatus}
            reloadPage={reloadPage}
            onCreateGoal={handleCreateGoal}
            onUpdateGoal={handleUpdateGoal}
            onDeleteGoal={handleDeleteGoal}
            uploadFile={uploadFile}
          />
        ))}
        <Goal
          type="empty-goal"
          onCreateGoal={handleCreateGoal}
          uploadFile={uploadFile}
        />
      </GoalsPanelContainer>
    </Page.RootContainer >
  );

  return pageContent;
}
