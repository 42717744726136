import styled from 'styled-components';

export const UpArrowContainer = styled.div`
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 2px;
  display: flex;

  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${props => props.theme.blueRoyal};
  transition: background-color 0.2s;

  &:hover {
    background: ${props => props.theme.blueNavy};
  }
`;

export const StyledUpArrow = styled.svg`
  path {
    stroke: ${props => props.theme.white};
  }
`;
