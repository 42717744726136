import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 0;

  @media (max-width: 1202px) {
    overflow-x: scroll;
  }

  &::-webkit-scrollbar {
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.graySilver};
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.grayPale};
    border-radius: 50px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &.extract-item {
    margin-top: 24px;
    margin-bottom: 16px;
    width: 97%;

    @media (max-width: 1440px) {
      margin-top: 18px;
      margin-bottom: 12px;
    }
  }
`;

export const GroupIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  height: min-content;
  gap: 13px;
  margin-top: 23px;
  padding-bottom: 24px;
`;

export const TextContainer = styled.div`
  &.th {
    display: flex;
    justify-content: center;
  }
  &.budget {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  &.patrimony {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &.pot-table {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 34px;
  }
`;

export const GroupItens = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.total {
    width: 350px;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: center;
  gap: 5px;

  &.set {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;
export const TitleCardWrapper = styled.div`
  width: 261px;
  min-width: 100px;
  display: flex;
  justify-content: space-between;
`;

export const Tr = styled.tr`
  &.receipt-table-title {
    width: 100%;

    &.receipts {
      background-color: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
    }

    &.debts {
      background-color: ${({ theme }) => theme.redBloodWithSlowOpacity};
    }

    &.investments {
      background-color: ${({ theme }) => theme.greenDark};
      opacity: 0.55;
    }

    &.spendings {
      background-color: ${({ theme }) => theme.blueSky};
      opacity: 0.55;
    }
  }

  &.receipt-table-body {
    height: 44px;
    border-bottom: 1px solid ${({ theme }) => theme.graySilver};
  }

  &.loading {
    border-bottom: none;
    height: 44px;
  }

  &.investment-panel-title {
    background-color: ${({ theme }) => theme.investmentPanelBackground};
    height: 58px;
    padding: 0 18px;
    border-radius: 8px;

    @media (max-width: 1023px) {
      height: 30.9px;
      padding: 0 9.6px;
      border-radius: 2.1px;
    }
  }

  &.investment-panel-body {
    height: 44px;
    border-bottom: 1px solid ${({ theme }) => theme.graySilver};
  }

  &.button {
    height: auto;
  }

  &.extract-item-title {
    border-bottom: 1px solid ${({ theme }) => theme.grayShadow};
  }

  &.extract-item-body {
    height: 48px;
    border-bottom: 1px solid ${({ theme }) => theme.graySilver};

    @media (max-width: 1440px) {
      height: 40px;
    }
  }
`;

export const Th = styled.th`
  &.budget-table-title {
    color: ${({ theme }) => theme.white};
    border: none;
    text-align: ${props => (props.first ? 'left' : 'center')};
    vertical-align: middle;
    height: 44px;
    padding: 12px 16px;
    font-size: 12px;
  }

  &.budget-table-title.checkbox {
    width: min-content;
    height: min-content;
    text-align: left;
  }

  &.budget-table-title.empyt {
    width: auto;
  }

  &.first-table-border {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &.last-table-border {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 50px;
  }
  &.debts {
    width: min-content;
    height: min-content;
    text-align: center;
  }

  &.loading {
    height: 58px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 4px;
  }

  &.investment-panel {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.white};
    height: min-content;

    &.first {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      text-align: left;
      padding-left: 18px;
    }

    &.last {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding-left: 80px;
      padding-right: 16px;
    }

    &.generic-class {
      padding-left: 96px;
    }

    @media (max-width: 1023px) {
      font-size: 8.5px;
      line-height: 8.5px;

      &.first {
        border-top-left-radius: 2.1px;
        border-bottom-left-radius: 2.1px;
        padding-left: 9.6px;
      }

      &.last {
        border-top-right-radius: 2.1px;
        border-bottom-right-radius: 2.1px;
        padding-left: 42.7px;
        padding-right: 8.5px;
      }

      &.generic-class {
        padding-left: 51.2px;
      }
    }
  }

  &.extract-item {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: ${({ theme }) => theme.grayShadow};
    padding-bottom: 24px;

    @media (max-width: 1440px) {
      font-size: 0.6rem;
      line-height: 0.6rem;
    }
  }

  &.extract-item.name {
    width: 15.58%;
  }

  &.extract-item.center {
    text-align: center;
  }
`;

export const Table = styled.table`
  &.extract-table{
    width: inherit;
    overflow: unset;
  }
  &.receipt-table {
    width: 100%;
    height: min-content;
    overflow: auto;
    border-collapse: collapse;

    td,
    th {
      padding: 10px;
    }

    @media (max-width: 1280px) {
      width: 938px;
    }
  }

  &.pot-table {
    width: 100%;
    margin-top: 18px;
    height: min-content;
    overflow: auto;
  }
  &.grafic-table {
    margin-top: 18px;
    width: 719px;
    height: 350px;
    min-width: 600px;

    td,
    th {
      padding: 10px;
    }

    @media (max-width: 1023px) {
      margin-top: 60px;
    }
  }

  &.min-table {
    width: 545px;
    height: min-content;

    @media (max-width: 1023px) {
      width: 100%;
    }
  }
  &.recom-table {
    width: 366px;
    height: min-content;
    @media (max-width: 1023px) {
      width: 100%;
    }
  }
  &.plan-table {
    width: 629px;
    height: min-content;
    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  &.investment-panel {
    width: 1556px;
    text-align: center;
    border-radius: 4px;
    table-layout: auto;

    @media (max-width: 1023px) {
      width: 829.9px;
      border-radius: 2.1px;
    }
  }
`;

export const Td = styled.td`
  &.receipt-table-body {
    text-align: ${props => (props.first ? 'left' : 'center')};
    color: ${({ theme }) => theme.grayShadow};
    vertical-align: middle;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 44px;
    padding: 12px 16px;
    font-size: 12px;
  }

  &.receipt-table-body.first {
    text-align: left;
    max-width: 15.78vw;
    min-width: 268px;
  }

  &.receipt-table-body.recurrent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &.receipt-table-body.status {
    font-weight: 700;
  }

  &.receipt-table-body.red {
    color: ${({ theme }) => theme.redLight};
    font-family: ${props => props.theme.fonts.PoppinsBold.fontFamily};
  }

  &.receipt-table-body.blue-lilac {
    color: ${({ theme }) => theme.blueLilac};
    font-family: ${props => props.theme.fonts.PoppinsBold.fontFamily};
  }

  &.receipt-table-body.blue-midnight {
    color: ${({ theme }) => theme.blueMidnight};
    font-family: ${props => props.theme.fonts.PoppinsBold.fontFamily};
  }

  &.receipt-table-body.checkbox {
    text-align: left;
    width: min-content;
  }

  &.receipt-table-body.options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &.receipt-table-body.right {
    width: min-content;
    height: min-content;
    text-align: right;
  }

  &.debts {
    width: min-content;
    height: min-content;
    text-align: right;
  }
  &.table-recom {
    display: flex;
    justify-content: center;
    width: 266px;
    height: 366px;
    overflow: auto;
  }

  &.spending {
    text-align: right;
    width: 230px;
  }

  &.investment-panel {
    font-size: 16px;
    line-height: 16px;
    color: ${({ theme }) => theme.grayShadow};
    height: 44px;
    text-align: center;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};

    &.first {
      text-align: left;
      padding-left: 18px;
    }

    &.last {
      padding-left: 80px;
      padding-right: 16px;
    }

    &.generic-class {
      padding-left: 96px;
    }

    @media (max-width: 1023px) {
      font-size: 8.5px;
      line-height: 8.5px;
      height: 30.1px !important;

      &.first {
        padding-left: 9.6px;
      }

      &.last {
        padding-left: 42.7px;
        padding-right: 8.5px;
      }

      &.generic-class {
        padding-left: 51.2px;
      }
    }
  }

  &.extract-item {
    font-size: 0.7rem;
    line-height: 0.7rem;
    color: ${({ theme }) => theme.grayShadow};
    font-weight: 400;

    @media (max-width: 1440px) {
      font-size: 0.6rem;
      line-height: 0.6rem;
    }
  }

  &.extract-item.center {
    text-align: center;
  }
`;

export const ContainerProgress = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  width: 420px;
  gap: 36px;
  min-width: 250px;
`;
