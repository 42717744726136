import { css } from 'styled-components';

export const NavigationCardBasicStyle = css`
    padding: 1.67vw;
    width: 24%;
    height: 13.33vw;
    border-radius: 0.83vw;
    background-color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    z-index: 1;
    box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.boxShadowColor};

    @media (max-width: 1600px) {
      border-radius: 16px;
      height: 213px;
      padding: 20px;
    }

    @media (max-width: 1370px) {
      width: 48%;
    }
`;
