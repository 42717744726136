import React from 'react';
import { Container, GroupItens } from './styles';
import { DetailedItem } from 'components/molecules/DetailedItem';
import { DetailedExtractFooter } from 'components/molecules/DetailedExtractFooter';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { EmptyCardRow } from 'components/atoms/EmptyCardRow';

export const DetailedExtract = ({
  total = 'R$ 0',
  entries = [],
  currentPage = '',
  isSearchActive = false,
  loadingsDetailedExtract = {
    loadingEntriesMore: false,
    loadingEntries: false,
  },
  setShowEditItemModal = () => {},
  setItemData = () => {},
  setPage = () => {},
  handleUpdateEntry = () => {},
}) => {
  const containerRef = React.useRef(null);

  const addNewItems = () => {
    setPage(page => page + 1);

    containerRef.current.scrollTop = containerRef.current.scrollTop - 200;
  };

  const handleScroll = React.useCallback(() => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

    if (
      scrollTop + clientHeight >= scrollHeight - 2 &&
      !isSearchActive &&
      !loadingsDetailedExtract.loadingEntriesMore &&
      !loadingsDetailedExtract.loadingInvestmentsMore
    ) {
      addNewItems();
    }
    // eslint-disable-next-line
  }, [isSearchActive, loadingsDetailedExtract]);

  if (
    loadingsDetailedExtract.loadingEntries ||
    loadingsDetailedExtract.loadingInvestments
  ) {
    return (
      <Container className="main">
        <GroupItens>
          {Array.from({ length: 4 }, (_, index) => (
            <GenericLoading key={index} className="detailed-extract-item" />
          ))}
        </GroupItens>
        <GenericLoading className="detailed-extract-footer" />
      </Container>
    );
  }

  return (
    <Container className="main">
      <GroupItens ref={containerRef} onScroll={handleScroll}>
        {entries.length === 0 && (
          <EmptyCardRow className="summary-card">
            Ainda não há lançamentos
          </EmptyCardRow>
        )}
        {entries.map((item, index) => (
          <DetailedItem
            key={index}
            editable={item.editable}
            categoryClassification={item.classification}
            categoryName={item.category_name}
            categoryItemName={item.item_name}
            releaseDate={item?.date_start.formatted || item?.date_start}
            value={item.value.formatted}
            paymentMethod={item?.type}
            installments={item?.installments}
            className="detailed-extract-item"
            classification={item.category_type}
            type={item?.type}
            setShowEditItemModal={setShowEditItemModal}
            setItemData={setItemData}
            itemData={item}
            handleUpdateEntry={handleUpdateEntry}
          />
        ))}
        {loadingsDetailedExtract.loadingEntriesMore &&
          Array.from({ length: 2 }, (_, index) => (
            <GenericLoading
              key={index}
              className="detailed-extract-item-loading"
            />
          ))}
      </GroupItens>
      <DetailedExtractFooter
        text="Total"
        value={total}
        className={`detailed-extract-footer ${currentPage}`}
      />
    </Container>
  );
};
