import styled from "styled-components";
import { NavigationCardBasicStyle } from "styles/components/NavigationCardBasicStyle";

export const RootContainer = styled.div`
    &.goal-panel,
    &.asset-management,
    &.asset-management-liquidity {
        ${NavigationCardBasicStyle}

        &.sealed {
            background-color: transparent;
            box-shadow: none;
            border: 1px solid ${({ theme }) => theme.blueRoyal};
        }
    }

    &.asset-management {
        &.hovered {
            transition: transform 0.3s ease-in-out;
            transform-origin: left end;
            background-color: ${({ theme }) => theme.blueRoyal};
            
            &:hover {
                transform: scale(1.05) translateX(10px) translateY(10px);
            }
        }
    }
`;

export const GroupItens = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.goal-panel {
        height: 36px;
    }

    &.asset-management {
        width: 100%;
        height: 108px;
        justify-content: left;
        gap: 44px;
        margin-top: 20px;

        @media (max-width: 1023px) {
            height: 57.6px;
            gap: 23.5px;
            margin-top: 10.7px;
        }
    }

    &.asset-management-container {
        height: 108px;
        flex-direction: column;

        @media (max-width: 1023px) {
            height: 57.6px;
        }
    }

    &.asset-management-container-liquity {
        width: 62px;
        padding: 1px 2.5px;

        @media (max-width: 1023px) {
            width: 33.1px;
            padding: 0.5px 1.3px;
        }      
    }
`;

export const AmountOfMoneyContainer = styled.div`

`;