const buildEntry = (entry, withRelations) => {
  const defaultEntry = {
    id: entry.id,
    item_id: entry.item_id || entry.selectedItem.value,
    description: entry.description,
    recurrent: entry.recurrent,
    value: entry.value.raw || entry.value,
    start: entry?.start ? new Date(entry?.start) : null,
    end: entry?.end ? new Date(entry?.end) : null,
  };
  if (withRelations) {
    defaultEntry.category_id = entry.category_id;
    defaultEntry.item_id = entry.item_id;
  }
  return defaultEntry;
};

const buildInstallment = installment => {
  const defaultEntry = {
    category_id: installment.category_id,
    item_id: installment.item_id,
    id: installment.id,
    name: installment.name,
    currentInstallment: installment.currentInstallment,
    totalInstallments: installment.totalInstallments,
    value: installment.value,
  };
  return defaultEntry;
};

const buildInvestment = investment => {
  return {
    id: investment.id,
    date: new Date(investment.date_start.raw),
    value: investment.value.raw,
  };
};
export { buildEntry, buildInstallment, buildInvestment };
