import styled from "styled-components";

export const StyledCalendarIconContainer = styled.div`
    &.generic-dream-modal {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 17px;
        height: 17px;
        cursor: pointer;
    }
`;

export const StyledCalendarIcon = styled.svg`
    &.generic-dream-modal {
        width: 14px;
        height: 14px;
    }
`;