import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React, { useState } from 'react';
import AddIcon from 'components/atoms/icons/AddIcon';
import TrashIcon from 'components/atoms/icons/TrashIcon';
import PencilIcon from 'components/atoms/icons/PencilIcon';
import { EditFinancialAssets } from 'components/molecules/PatrimonyMapModal/EditFinancialAssets';
import { AddFinancialAssets } from 'components/molecules/PatrimonyMapModal/AddFinancialAssets';
import { DeleteFinancialAssets } from 'components/molecules/PatrimonyMapModal/DeleteFinancialAssets';
import Checkbox from 'components/atoms/Checkbox';
import { getActiveFinancial } from 'utils/patrimony';
import toast from 'react-hot-toast';

export const FinancialAssets = ({
  financialAssets,
  tooltipText,
  reloadPage,
  isProcessing,
  onCreate = () => {},
  onUpdate = () => {},
  handleToggleStatus = () => {},
  handleToggleStatusPension = () => {},
  handleDeleteActive = () => {},
}) => {
  const columnOrder = [
    'name',
    'categorization',
    'seleable',
    'private_pension',
    'liquidity',
    'value',
  ];

  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [activeRowData, setActiveRowData] = useState(null);

  const [selectedItems, setSelectedItems] = useState(
    financialAssets.patrimonies.map(() => false)
  );

  const handleCheckboxChange = index => {
    const updatedSelectedItems = selectedItems.map((item, idx) =>
      idx === index ? !item : item
    );
    setSelectedItems(updatedSelectedItems);
  };

  const handleSelectAllChange = () => {
    const allSelected = selectedItems.every(item => item);
    const updatedSelectedItems = selectedItems.map(() => !allSelected);
    setSelectedItems(updatedSelectedItems);
  };
  const handleDeleteClick = () => {
    const selectedIds = selectedItems
      .map((isSelected, index) =>
        isSelected ? financialAssets.patrimonies[index]?.id : null
      )
      .filter(id => id !== null);

    if (selectedIds.length > 0) {
      setActiveRowData(selectedIds);
      setShowDelete(true);
    } else {
      toast.error('Selecione pelo menos um item!');
    }
  };

  return (
    <Card.Container className="spending-table">
      <Table.TitleInformation
        nameTable="Ativos Financeiros"
        className="receipts patrimony font-regular "
        tooltipText={tooltipText}
        value={financialAssets.totalFormatted}
        percentage={financialAssets.percentage.formatted}
      />
      <Table.Container className="responsive">
        <Table.TableBasic className="receipt-table">
          <Table.Thead>
            <Table.Tr className="receipt-table-title receipts">
              <Table.Th className="budget-table-title checkbox first-table-border">
                <Checkbox
                  checked={selectedItems.every(item => item)}
                  onChange={handleSelectAllChange}
                />
              </Table.Th>
              {getActiveFinancial().map((item, index) => (
                <Table.Th
                  className={`budget-table-title `}
                  first={index === 0}
                  key={index}
                >
                  {item}
                </Table.Th>
              ))}
              <Table.Th className="budget-table-title empty last-table-border" />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {financialAssets.patrimonies.map((column, rowIndex) => (
              <Table.Tr className="receipt-table-body" key={rowIndex}>
                <Table.Td className="receipt-table-body checkbox">
                  <Checkbox
                    checked={selectedItems[rowIndex]}
                    onChange={() => handleCheckboxChange(rowIndex)}
                  />
                </Table.Td>
                {columnOrder.map((item, index) => {
                  let currentItem = column[item];

                  if (item === 'seleable') {
                    return (
                      <Table.Td className="receipt-table-body " key={index}>
                        <Table.Switch
                          checked={column.sealable}
                          onClick={() => {
                            if (!isProcessing) {
                              handleToggleStatus(column.id, column.sealable);
                            }
                          }}
                        />
                      </Table.Td>
                    );
                  }
                  if (item === 'private_pension') {
                    return (
                      <Table.Td className="receipt-table-body " key={index}>
                        <Table.Switch
                          checked={column.sealable}
                          onClick={() => {
                            if (!isProcessing) {
                              handleToggleStatusPension(
                                column.id,
                                column.sealable
                              );
                            }
                          }}
                        />
                      </Table.Td>
                    );
                  }

                  return (
                    <Table.Td
                      className={`receipt-table-body `}
                      first={item === 'name'}
                      key={index}
                    >
                      {currentItem}
                    </Table.Td>
                  );
                })}
                <Table.Td>
                  <PencilIcon
                    className="category-title"
                    onClick={() => {
                      setActiveRowData(column);
                      setShowEdit(true);
                    }}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.TableBasic>
      </Table.Container>
      <Table.GroupIcons>
        <AddIcon
          className="receipt-table"
          onClick={() => {
            setShowAdd(true);
          }}
        />
        {showAdd && (
          <AddFinancialAssets
            showModal={showAdd}
            setShowModal={setShowAdd}
            onConfirm={reloadPage}
            onCreate={onCreate}
            theme="patrimony"
          />
        )}
        <TrashIcon className="receipt-table" onClick={handleDeleteClick} />
        {showDelete && (
          <DeleteFinancialAssets
            showModal={showDelete}
            setShowModal={setShowDelete}
            selectedItem={activeRowData}
            onConfirm={() => handleDeleteActive(activeRowData)}
            theme="debts"
          />
        )}
      </Table.GroupIcons>
      {showEdit && (
        <EditFinancialAssets
          showModal={showEdit}
          setShowModal={setShowEdit}
          onConfirm={reloadPage}
          onUpdate={onUpdate}
          theme="edit"
          activeProps={activeRowData}
        />
      )}
    </Card.Container>
  );
};
