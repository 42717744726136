import styled from "styled-components";

export const CloudWithAddIconContainer = styled.svg`
    &.empty-goal-panel {
        width: 64px;
        height: 45px;

        path {
            stroke: ${({ theme }) => theme.graySilver};
        }
    }
`;