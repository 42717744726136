import PencilIcon from 'components/atoms/icons/PencilIcon';
import { EditRealEstateList } from 'components/molecules/RetirementMapModal/EditRealEstateList';
import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React, { useState } from 'react';

export const RealEstateList = ({ realEstateList }) => {
  const columnOrder = [
    'properties',
    'marketValue',
    'rent',
    'monthlyExpenses',
    'netRevenue',
    'profitability',
    'recommendation',
  ];

  const getClassByStatus = status => {
    const statusClasses = {
      VENDER: 'red',
      MANTER: 'blue-lilac',
      ALUGAR: 'blue-midnight',
    };

    return statusClasses[status] || '';
  };

  const [showEdit, setShowEdit] = useState(false);

  return (
    <Card.Container className="spending-table">
      <Table.TitleTotal
        nameTable="Relação dos Imóveis"
        className=" patrimony font-regular "
        value={realEstateList.total}
        percentage={realEstateList.percentage}
      />
      <Table.Container className="responsive">
        <Table.TableBasic className="receipt-table">
          <Table.Thead>
            <Table.Tr className="receipt-table-title receipts">
              {realEstateList.header.map((item, index) => (
                <Table.Th
                  className={`budget-table-title ${index === 0 &&
                    'first-table-border checkbox'}`}
                  first={index === 0}
                  key={index}
                >
                  {item}
                </Table.Th>
              ))}
              <Table.Th className="budget-table-title empty last-table-border" />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {realEstateList.body.map((column, rowIndex) => (
              <Table.Tr className="receipt-table-body" key={rowIndex}>
                {columnOrder.map((item, index) => {
                  const currentItem = column[item];
                  if (item === 'recommendation') {
                    return (
                      <Table.Td
                        className={`receipt-table-body ${getClassByStatus(
                          currentItem
                        )}`}
                        key={index}
                      >
                        {currentItem}
                      </Table.Td>
                    );
                  }

                  return (
                    <Table.Td
                      className={`receipt-table-body ${item === 'item' &&
                        'first-table-border checkbox'}`}
                      first={item === 'item'}
                      key={index}
                    >
                      {currentItem}
                    </Table.Td>
                  );
                })}
                <Table.Td>
                  <PencilIcon
                    className="category-title"
                    onClick={() => {
                      setShowEdit(true);
                    }}
                  />
                  {showEdit && (
                    <EditRealEstateList
                      showModal={showEdit}
                      setShowModal={setShowEdit}
                      theme="edit"
                    />
                  )}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.TableBasic>
      </Table.Container>
    </Card.Container>
  );
};