import React, { useContext } from 'react';
import { LoadingContainer } from './styles';
import TitleCard from 'components/molecules/TitleCard';
import { SpendingCard, Container, GroupEmptyCard } from './styles';
import { EmptyCardRow } from 'components/atoms/EmptyCardRow';
import { GeneralContext } from 'utils/contexts/GeneralContext';

const RetirementManagement = ({
  toolTipText,
  url,
  title,
  showToolTip,
  className,
}) => {
  const { loading } = useContext(GeneralContext);

  if (loading) {
    return (
      <Container className={`${className} empty`}>
        <TitleCard
          url={url}
          title={title}
          loading={loading}
          className={className}
          showToolTip={showToolTip}
          tooltipText={toolTipText}
        />
        <LoadingContainer />
      </Container>
    );
  }

  return (
    <Container className={`${className} empty`}>
      <TitleCard
        url={url}
        title={title}
        loading={loading}
        className={`${className} title-card retirement`}
        showToolTip={showToolTip}
        tooltipText={toolTipText}
      />
      <GroupEmptyCard>
        <SpendingCard className="empty">
          <EmptyCardRow className="summary-card">
            Ainda não há dados cadastrados
          </EmptyCardRow>
        </SpendingCard>
      </GroupEmptyCard>
    </Container>
  );
};

export default RetirementManagement;
