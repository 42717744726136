import React from 'react';
import { Container, Wrapper, IconContainer } from '../styles';
import { GenericLoading } from 'components/atoms/GenericLoading';

export const CategoryIntroductionLoading = () => (
  <div>
    <Container className="header">
      <Wrapper className="extract-title">
        <GenericLoading className="extract-category-title" />
      </Wrapper>
      <Wrapper className="extract-title">
        <GenericLoading className="extract-category-title-text" />
      </Wrapper>
    </Container>
    <Container className="body">
      <GenericLoading className="extract-category-title-progressbar" />
      <IconContainer className="extract-category">
        <GenericLoading className="extract-category-title-icon" />
      </IconContainer>
    </Container>
  </div>
);
