import styled from "styled-components";

export const CardContainer = styled.div`
    margin-top: 36px;
    display: flex;
    gap: 32px 0;
    flex-wrap: wrap;
    justify-content: space-between;

    ::-webkit-scrollbar {
        width: 10px;
        height: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.grayWebkitScrollbarThumb};
        border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.webkitScrollbarTrack};
    }
`;