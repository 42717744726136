import styled from 'styled-components';

export const Overlay = styled.div`
  &.default {
    z-index: 1000;
    position: relative;

    &.top {
      bottom: 300%;
    }
  }
`;

export const CalendarContainer = styled.div`
  &.default {
    width: 240px;
    height: auto;
    border-radius: 8px;
    padding: 10px;
    background-color: ${props => props.theme.white};
    box-shadow: 0px 3px 10px ${props => props.theme.blackWithSlowOpacity};
    inline-size: auto;
  }
`;

export const CalendarTitle = styled.div`
  &.default {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
  }
`;

export const Title = styled.div`
  &.default {
    padding: 4px 6px;
    font-weight: 500;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    font-size: 0.95rem;
    line-height: 1rem;
    color: ${props => props.theme.grayShadow};
  }
`;

export const ArrowsContainer = styled.div`
  &.default {
    width: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Day = styled.div`
  &.default {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${props => props.theme.fonts.PoppinsRegular.fontFamily};
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 400;
    color: ${props => props.theme.blueNavy};
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;

    ${props =>
    props.selected === 'currentMonth' &&
    `
        background: ${props.theme.blueRoyal};
        color: ${props.theme.white};
    `}

    ${props =>
    props.type !== 'currentMonth' &&
    `
        color: ${props.theme.graySilver};
    `}

    ${props =>
    props.type === 'title' &&
    `
        color: ${props.theme.white};
        cursor: default;
    `}

    &:hover {
      background-color: ${props => props.theme.graySilver};
      color: ${props => props.theme.white};
    }
  }
`;

export const DaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  margin-top: 10px;
  padding: 0 2px;

  ${props =>
    props.type === 'title' &&
    `
    background-color: ${props.theme.blueRoyal};
    border-radius: 6px;
    margin-top: 5px;
  `}
`;

export const ButtonContainer = styled.div`
  &.default {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
`;
