import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';
import { passivePatrimonialSchema } from 'utils/validator';
import toast from 'react-hot-toast';

export const AddPassive = ({
  showModal = false,
  setShowModal = () => {},
  activeProps,
  onConfirm = () => {},
  onCreate = () => {},
  theme,
  type,
}) => {
  const [name, setName] = useState(activeProps?.name);
  const [institution, setInstitution] = useState(activeProps?.institution);
  const [remainingInstallments, setRemainingInstallments] = useState(
    activeProps?.remainingInstallments
  );
  const [installment, setInstallment] = useState(activeProps?.installment);
  const [rate, setRate] = useState(activeProps?.rate);
  const [inCash, setInCash] = useState(activeProps?.inCash);
  const [remainingValue, setRemainingValue] = useState(
    activeProps?.remainingValue
  );
  const [discount, setDiscount] = useState(activeProps?.discount);

  const validateFields = () => {
    let errors = {};

    try {
      passivePatrimonialSchema.validateSync({
        name,
        institution,
        installment,
        remainingInstallments,
        inCash,
        remainingValue,
        discount,
      });
    } catch (error) {
      errors = { ...errors, [error.path]: error.message };
    }

    return errors;
  };

  const handleSave = async () => {
    const errors = validateFields();

    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach(error => {
        toast.error(error);
      });
      return;
    }

    const data = {
      name,
      type,
      institution,
      installment,
      remainingInstallments,
      rate,
      inCash,
      remainingValue,
      discount,
    };

    try {
      const response = await onCreate(data);
      onConfirm(response);
    } catch (error) {
      console.error(error);
      toast.error('Erro ao criar o ativo material.');
    } finally {
      setShowModal(false);
    }
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Cadastrar Novo Passivo"
          onClose={() => setShowModal(false)}
          type="add"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="simple-modal"
          label="Nome do passivo"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.InputText
          className="simple-modal after"
          label="Instituição"
          getInputValue={setInstitution}
          defaultValue={institution}
        />
        <Modal.GroupInputs show>
          <Modal.InputText
            className="simple-modal after "
            label="Parcelas restantes"
            getInputValue={setRemainingInstallments}
            defaultValue={remainingInstallments}
          />
          <Modal.InputPercentage
            label="Taxa"
            getInputValue={setRate}
            getRawInputValue={setRate}
            className="simple-modal after"
            defaultValue={rate}
          />
        </Modal.GroupInputs>
        <Modal.GroupInputs show>
          <Modal.InputMoney
            className="simple-modal after "
            label="Valor da parcela"
            getInputValue={setInstallment}
            defaultValue={installment}
          />

          <Modal.InputMoney
            className="simple-modal after "
            label="Valor à vista"
            getInputValue={setInCash}
            defaultValue={inCash}
          />
        </Modal.GroupInputs>
        <Modal.GroupInputs show>
          <Modal.InputMoney
            className="simple-modal after disabled "
            label="Saldo Atual"
            getInputValue={setRemainingValue}
            defaultValue={remainingValue}
            disabled={true}
          />

          <Modal.InputPercentage
            className="simple-modal after disabled "
            label="Desconto"
            getInputValue={setDiscount}
            getRawInputValue={setDiscount}
            defaultValue={discount}
          />
        </Modal.GroupInputs>
      </Modal.BodyContainer>
      <Modal.FooterWithOneButton
        onClick={handleSave}
        className="generic-dream-modal"
        text="Confirmar"
      />
    </Modal.Container>
  );
};
