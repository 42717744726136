import React, { useMemo } from 'react';
import { LoadingTable } from 'components/templates/Table/LoadingTable';
import { RealEstateList } from './RealEstateList';
import { SocialSecurity } from './SocialSecurity';
import { FinancialPatrimony } from './FinancialPatrimony';
import { Rentirement } from './Rentirement';
import {
  getFinancialPatrimony,
  getRetirement,
  getSocialSecurity,
} from 'development/retirement';
import { useRetirementPatrimonies } from 'hooks/useActivePatrimony';

export const RetirementMapTable = ({ currentPage }) => {
  // Memoize the data to execute only once
  const socialSecurityData = useMemo(() => getSocialSecurity(), []);
  const financialPatrimonyData = useMemo(() => getFinancialPatrimony(), []);
  const retirementData = useMemo(() => getRetirement(), []);

  const pages = {
    socialSecurity: <SocialSecurity socialSecurity={socialSecurityData} />,
    rent: <RealEstateListWrapper />,
    financialPatrimony: (
      <>
        <FinancialPatrimony financialPatrimony={financialPatrimonyData} />
        <Rentirement rentirement={retirementData} />
      </>
    ),
  };

  // Fallback for invalid pages
  return pages[currentPage] || null;
};

// Wrapper component for real estate list
const RealEstateListWrapper = () => {
  const { items, loading } = useRetirementPatrimonies();

  if (loading) {
    return <LoadingTable />;
  }
  console.log('passou aqui')
  return !loading && <RealEstateList patrimonies={items} />;
};
