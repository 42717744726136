import styled from "styled-components";

export const StyledContainer = styled.div`
    &.financial-independence {
        width: 23px;
        height: 24px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: ${({ theme }) => theme.sideBarHover};
        }
    }

    &.realized-dream {
        width: 23px;
        height: 24px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;

        path:first-child {
            fill: ${({ theme }) => theme.blueSky};
            stroke: ${({ theme }) => theme.blueSky};
        }
        
        &:hover {
            background-color: ${({ theme }) => theme.sideBarHover};
            
            path:first-child {
                fill: ${({ theme }) => theme.sideBarHover};
                stroke: ${({ theme }) => theme.white};
            }
        }
    }
`;

export const StyledVerifiedIcon = styled.svg`
    &.financial-independence, &.realized-dream {
        width: 17.61px;
        height: 17.61px;
        cursor: pointer;

        path {
            stroke: ${({ theme }) => theme.white};
        }
    }
`;