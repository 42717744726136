import React, { useMemo, useState } from 'react';
import { PageContainer, Wrapper, Container, ExtractWrapper } from './styles';
import { ExtractMenu } from 'components/organisms/ExtractMenu';
import { CategoryIntroduction } from 'components/organisms/CategoryIntroduction';
import { DetailedExtractMenu } from 'components/organisms/DetailedExtractMenu';
import { DetailedExtract } from 'components/organisms/DetailedExtract';
import { SimpleEditExtractItemModal } from 'components/molecules/SimpleEditExtractItemModal';
import { SimpleDeleteModal } from 'components/molecules/BudgetModal/SimpleDeleteModal';
import {
  useCategories,
  useCategoriesList,
} from '../../../../hooks/useCategories';
import { useItems, useItemsList } from '../../../../hooks/useItems';
import { CategoryIntroductionLoading } from 'components/organisms/CategoryIntroduction/CategoryIntroductionLoading';
import {
  useFetchEntriesByCategoryType,
  useFetchEntries,
  useUpdateEntry,
  useDeleteEntry,
} from 'hooks/useEntries';
import {
  useUpdateInstallment,
  useDeleteInstallment,
} from 'hooks/useInstallments';
import { getCurrentMonthObject } from 'utils/date';
import { buildEntry, buildInstallment } from 'services/builders';
import {
  useDeleteInvestment,
  useFetchInvestmentsByCategory,
  useFetchInvestmentsEntries,
  useInvestments,
  useInvestmentsItems,
  useUpdateInvestment,
} from 'hooks/useInvestments';
import { EditInvestmentModal } from 'components/molecules/EditInvestmentModal';

export default function PeriodStatementPage() {
  const [currentPage, setCurrentPage] = useState('spendings');

  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [itemData, setItemData] = useState({});
  const [loadingDeleteItemModal, setLoadingDeleteItemModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [, setItems] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [startDate, setStartDate] = React.useState(
    getCurrentMonthObject(new Date())
  );
  const [endDate, setEndDate] = React.useState(
    getCurrentMonthObject(new Date())
  );
  const investmentsHook = useInvestments(
    startDate.value,
    endDate.value,
    currentPage
  );

  const categoriesHook = useCategories(
    startDate.value,
    endDate.value,
    currentPage
  );

  const {
    categories,
    loading: loadingCategories,
    total,
    fetchData: reloadCategories,
  } = currentPage === 'investments' ? investmentsHook : categoriesHook;

  const investmentsItemsHook = useInvestmentsItems(
    selectedCategoryId,
    startDate.value,
    endDate.value
  );
  const itemsHook = useItems(
    selectedCategoryId,
    startDate.value,
    endDate.value
  );

  const { items: itemsByCategory, loading: loadingItems, reloadItems } =
    currentPage === 'investments' ? investmentsItemsHook : itemsHook;

  const entriesByCategoryTypeHook = useFetchEntriesByCategoryType(
    currentPage,
    startDate.value,
    endDate.value,
    inputSearch,
    currentPage
  );
  const investmentsEntriesByCategoryTypeHook = useFetchInvestmentsByCategory(
    currentPage,
    startDate.value,
    endDate.value,
    inputSearch,
    currentPage
  );

  const {
    entries,
    loadings: loadingsEntries,
    setPage,
    page,
    isSearchActive,
    totalEntriesSearch,
    reloadEntries,
  } =
    currentPage === 'investments'
      ? investmentsEntriesByCategoryTypeHook
      : entriesByCategoryTypeHook;

  const { deleteEntry } = useDeleteEntry();
  const { updateEntry } = useUpdateEntry();

  const { updateInstallment } = useUpdateInstallment();

  const { deleteInstallment } = useDeleteInstallment();
  const { updateInvestment } = useUpdateInvestment();
  const { deleteInvestment } = useDeleteInvestment();
  const {
    categories: categoriesListModalEdit,
    fetchCategoriesList,
    loading: loadingCategoriesListModalEdit,
  } = useCategoriesList();

  const {
    fetchItemsListByCategoryId,
    items: itemsListModalEdit,
    loading: loadingItemsListModalEdit,
  } = useItemsList();

  const defaultPlaceholderSearch = useMemo(() => {
    if (currentPage === 'investments') {
      return 'Somente valor...';
    }
    return 'Categoria, nome, valor...';
  }, [currentPage]);
  const investmentsEntriesHook = useFetchInvestmentsEntries(
    selectedItemId,
    startDate.value,
    endDate.value
  );

  const entriesHook = useFetchEntries(
    selectedItemId,
    startDate.value,
    endDate.value
  );

  const {
    entries: entriesByItemId,
    loading: loadingEntries,
    fetchEntries,
    reload: reloadEntriesByItemId,
  } = currentPage === 'investments' ? investmentsEntriesHook : entriesHook;

  const handleDelete = async (itemData, setShowDeleteItemModal) => {
    if (currentPage === 'investments') {
      await deleteInvestment(itemData.id, setShowDeleteItemModal);
      await Promise.all([
        reloadEntriesByItemId(),
        reloadCategories(currentPage),
        reloadItems(),
        reloadEntries(),
      ]);
      return;
    }
    if (itemData.hasOwnProperty('totalInstallments')) {
      await deleteInstallment(itemData.id, setShowDeleteItemModal);
    } else {
      await deleteEntry(itemData.id, setShowDeleteItemModal);
    }

    await Promise.all([
      reloadEntriesByItemId(),
      reloadCategories(currentPage),
      reloadItems(),
      reloadEntries(),
    ]);
  };

  const handleUpdate = async (id, type, setShowEditItemModal, data) => {
    if (type === 'investments') {
      await updateInvestment(id, data, setShowEditItemModal);
      await Promise.all([
        reloadEntriesByItemId(),
        reloadCategories(currentPage),
        reloadItems(),
        reloadEntries(),
      ]);
      return;
    }
    if (type === 'Parcelado') {
      const installmentUpdate = buildInstallment({
        id,
        item_id: data.selectedItem.value,
        category_id: data.selectedCategory.value,
        name: data.name,
        value: data.value,
        currentInstallment: data.currentInstallment,
        totalInstallments: data.totalInstallments,
      });
      await updateInstallment(id, installmentUpdate, setShowEditItemModal);
      await Promise.all([
        reloadEntriesByItemId(),
        reloadCategories(currentPage),
        reloadItems(),
        reloadEntries(),
      ]);
      return;
    }
    const entryUpdate = buildEntry(data, true);
    await updateEntry(entryUpdate.id, entryUpdate, setShowEditItemModal);
    await Promise.all([
      reloadEntriesByItemId(),
      reloadCategories(currentPage),
      reloadItems(),
      reloadEntries(),
    ]);
    return;
  };

  return (
    <PageContainer>
      {showEditItemModal && currentPage === 'investments' && (
        <EditInvestmentModal
          showModal={showEditItemModal}
          setShowModal={setShowEditItemModal}
          title="Editar investimento"
          data={itemData}
          handleUpdate={handleUpdate}
          onCancel={() => {
            setShowEditItemModal(false);
          }}
          onExclude={() => {
            setShowEditItemModal(false);
            setShowDeleteItemModal(true);
          }}
        />
      )}
      <SimpleEditExtractItemModal
        currentPage={currentPage}
        showModal={showEditItemModal && currentPage !== 'investments'}
        setShowModal={setShowEditItemModal}
        categoriesListModalEdit={categoriesListModalEdit}
        fetchCategoriesList={fetchCategoriesList}
        itemsListModalEdit={itemsListModalEdit}
        fetchItemsListByCategoryId={fetchItemsListByCategoryId}
        title="Editar item"
        data={itemData}
        loading={loadingCategoriesListModalEdit || loadingItemsListModalEdit}
        handleUpdate={handleUpdate}
        onCancel={() => {
          setShowEditItemModal(false);
        }}
        onExclude={() => {
          setShowEditItemModal(false);
          setShowDeleteItemModal(true);
        }}
      />

      <SimpleDeleteModal
        showModal={showDeleteItemModal}
        setShowModal={setShowDeleteItemModal}
        title="Excluir item"
        text="Excluir item?"
        highlightedText={'Esta ação é permanente e não poderá ser desfeita'}
        confirmButtonText="Excluir item"
        cancelButtonText="Cancelar"
        loading={loadingDeleteItemModal}
        setLoading={setLoadingDeleteItemModal}
        onConfirm={() => {
          handleDelete(itemData, setShowDeleteItemModal);
        }}
        onCancel={() => {
          setShowDeleteItemModal(false);
          setItemData({});
        }}
      />

      <Container>
        <Wrapper className="extract">
          <ExtractMenu
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={loadingCategories}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <ExtractWrapper>
            {loadingCategories &&
              Array.from({ length: 8 }).map((_, index) => {
                return <CategoryIntroductionLoading key={index} />;
              })}

            <CategoryIntroduction
              categories={categories}
              setItems={setItems}
              itemsByCategory={itemsByCategory}
              currentPage={currentPage}
              setShowEditItemModal={setShowEditItemModal}
              setItemData={setItemData}
              selectedCategoryId={selectedCategoryId}
              setSelectedCategoryId={setSelectedCategoryId}
              intervalDate={{ start: startDate.value, end: endDate.value }}
              loadingCategories={loadingCategories}
              loadingItems={loadingItems}
              selectedItemId={selectedItemId}
              setSelectedItemId={setSelectedItemId}
              fetchEntriesByItemId={fetchEntries}
              loadingEntries={loadingEntries}
              entriesByItemId={entriesByItemId}
              handleUpdate={handleUpdate}
            />
          </ExtractWrapper>
        </Wrapper>
        <Wrapper className="detailed-extract">
          <DetailedExtractMenu
            loading={loadingsEntries.loadingEntries}
            inputSearch={inputSearch}
            setInputSearch={setInputSearch}
            placeholder={defaultPlaceholderSearch}
          />
          <DetailedExtract
            entries={entries}
            total={isSearchActive ? totalEntriesSearch : total}
            currentPage={currentPage}
            loadingsDetailedExtract={loadingsEntries}
            page={page}
            isSearchActive={isSearchActive}
            setPage={setPage}
            setShowEditItemModal={setShowEditItemModal}
            setItemData={setItemData}
            // handleUpdate={handleUpdate}
          />
        </Wrapper>
      </Container>
    </PageContainer>
  );
}
