import React, { useState, useEffect } from 'react';
import { Card } from 'components/templates/Card';
import { GenericLoading } from 'components/atoms/GenericLoading';

export const Active = ({
  title = 'Ativos',
  icon = 'active',
  currentPage,
  setCurrentPage,
  totalValue,
  loading,
}) => {
  const [selected, setSelected] = useState(currentPage === 'active');
  const [hovered, setHovered] = useState(currentPage === 'active');

  const select = selected || hovered ? 'selected-patrimony' : 'passive';
  const selectedClass =
    selected || hovered ? 'selected-patrimony' : 'patrimony';

  const handleClick = () => {
    setSelected(!selected);
    setCurrentPage('active');
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    setSelected(currentPage === 'active');
    setHovered(currentPage === 'active');
  }, [currentPage]);

  if (loading) {
    return <GenericLoading className="budget-card" />;
  }

  return (
    <Card.Container
      selected={selected}
      className={`budget ${selectedClass}`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Card.BodyContainer className={`budget `}>
        <Card.Title
          title={title}
          type={icon}
          className={`spending-card ${select} blue`}
        />
      </Card.BodyContainer>
      <Card.Content className={`budget `}>
        <Card.ContentMoney
          className={`${select} blue`}
          label="Valor total"
          value={totalValue}
        />
      </Card.Content>
    </Card.Container>
  );
};
