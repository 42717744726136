import React from 'react';
import { Card } from 'components/templates/Card';
import { GenericLoading } from 'components/atoms/GenericLoading';

export const CoverageIndex = ({
  title = 'Índice de Cobertura',
  icon = 'coverageIndex',
  totalValue,
  text,
  loading,
}) => {
  if (loading) {
    return <GenericLoading className="budget-card" />;
  }

  return (
    <Card.Container className={`empyt`}>
      <Card.BodyContainer className={`budget empyt`}>
        <Card.TitleInformation
          title={title}
          type={icon}
          className="spending-card passive blue patrimony"
          tooltipText={text}
        />
      </Card.BodyContainer>
      <Card.Content className={`budget passive `}>
        <Card.ContentMoney
          className="passive blue"
          label="Meses com cobertura"
          value={totalValue}
        />
      </Card.Content>
    </Card.Container>
  );
};
