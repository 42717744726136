import styled from 'styled-components';

export const SVG = styled.svg`
  width: 13px;
  height: 13px;

  path {
    fill: ${({ theme }) => theme.graySilver};
  }



`;
