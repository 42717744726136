import { useState, useEffect, useCallback } from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  formatDateISO,
} from 'utils/date';
const useCategories = (start = new Date(), end = new Date(), currentPage) => {
  const { id: userId } = useSelector(state => state.user.profile);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(
    async currentPage => {
      if (currentPage === 'investments') return setCategories([]);
      let parsedStart = getFirstDayOfMonth(new Date(start));
      let parsedEnd = getLastDayOfMonth(new Date(end));

      parsedStart = formatDateISO(parsedStart);
      parsedEnd = formatDateISO(parsedEnd);
      setLoading(true);
      setCategories([]);
      try {
        const { data: categoriesData } = await api.get(
          `/categories/${userId}/budget-detail`,
          {
            params: {
              start: parsedStart,
              end: parsedEnd,
              type: currentPage,
            },
          }
        );
        setTotal(categoriesData.totalValue.formatted);

        setCategories(categoriesData.categories);
      } catch (error) {
        console.error('Error fetching extract data:', error);
        toast.error('Ocorreu um erro ao buscar os dados do extrato.');
      } finally {
        setLoading(false);
      }
      // eslint-disable-next-line
    },

    [userId, start, end]
  );

  useEffect(() => {
    if (userId && currentPage) {
      fetchData(currentPage);
    }
    // eslint-disable-next-line
  }, [fetchData, userId, currentPage]);

  return {
    categories,
    total,
    loading,
    fetchData,
  };
};

const useCategoriesList = () => {
  const { id: userId } = useSelector(state => state.user.profile);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCategoriesList = useCallback(
    async currentPage => {
      setLoading(true);
      setCategories([]);
      try {
        const { data: categoriesData } = await api.get(
          `users/${userId}/categories`,
          {
            params: {
              type: currentPage,
            },
          }
        );
        setCategories(categoriesData);
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast.error('Ocorreu um erro ao buscar as categorias.');
      } finally {
        setLoading(false);
      }
    },
    [userId]
  );

  return {
    categories,
    loading,
    fetchCategoriesList,
  };
};

export { useCategories, useCategoriesList };
