import { useState, useEffect, useCallback, useContext } from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import { add, format } from 'date-fns';
import { monthNumberToName } from 'utils/date';
import { GeneralContext } from 'utils/contexts/GeneralContext';

const defaultOverview = () => {
    const today = new Date();
    const values = [];

    for (let i = 5; i >= 0; i--) {
        const before = add(today, {
            months: -i
        })
        const name = monthNumberToName(before.getMonth() + 1);
        const initials = name.substring(0, 3);
        const year = before.getFullYear();
        const formattedName = `${initials} ${year}`;
        values.push({
            name: formattedName,
            value: "R$ 0"
        })
    }
    return values;
}

const defaultLoadedData = {
    spendings: null,
    receipt: null,
    investment: null,
}

const useReport = () => {
    const { id: userId } = useSelector(state => state.user.profile);
    const [spendingByMonth, setSpendingByMonth] = useState(defaultOverview());
    const [currentPage, setCurrentPage] = useState('spendings');
    const [baseDate, setBaseDate] = useState(new Date());
    const [bodyData, setBodyData] = useState([]);
    const [loadingBody, setLoadingBody] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const [loadedData, setLoadedData] = useState(defaultLoadedData);
    const { setLoading } = useContext(GeneralContext);

    const updateLoading = useCallback((value) => {
        console.log('entrei em updateLoading');
        setLoading(value);
        setLoadingBody(value);
    }, [setLoading, setLoadingBody])

    const fetchValues = useCallback(async () => {
        try {
            updateLoading(true);
            if (!userId) {
                throw new Error('Usuário não encontrado');
            }

            const translate = {
                spendings: 'spendings',
                receipt: 'receipts',
                investment: 'investments',
            };

            if (loadedData[currentPage]) {
                setSpendingByMonth(loadedData[currentPage]?.overview || defaultOverview());
                setBodyData(loadedData[currentPage]?.body || []);
                return true;
            }

            const response = await api.get(`/budget/half_yearly_report/${userId}`, {
                params: {
                    formated_value: true,
                    type: translate[currentPage],
                    end: format(baseDate, 'yyyy-MM-dd'),
                },
            });
            const { data } = response.data;
            setSpendingByMonth(data?.overview || defaultOverview());
            setBodyData(data?.body || []);
            setLoadedData({ ...loadedData, [currentPage]: data });
        } catch (error) {
            console.error(error);
            setSpendingByMonth(defaultOverview());
            setBodyData([]);
            throw new Error(error);
        } finally {
            updateLoading(false);
        }
    }, [
        userId,
        currentPage,
        baseDate,
        setSpendingByMonth,
        setBodyData,
        updateLoading,
        loadedData,
        setLoadedData,
    ]);

    const fetchItemValue = useCallback(async (id) => {
        try {
            updateLoading(true);

            const response = await api.get(`items/${id}`, {
                params: {
                    formated_value: true,
                },
            });
            const { data } = response.data;
            console.log('busquei aqui no hook ', data);
            setSelectedItem(data);
        } catch (error) {
            throw new Error(error);
        } finally {
            updateLoading(false);
        }
    }, [setSelectedItem, updateLoading]);

    const updateMonthData = useCallback(async (month) => {
        setBaseDate(add(baseDate, { months: month }));
        setLoadedData(defaultLoadedData);
    }, [baseDate, setBaseDate]);

    const updateItem = useCallback(async (itemId, data) => {
        await api.put(`items/${itemId}`, data);
        setSelectedItem(null);
        setLoadedData({
            ...loadedData,
            [currentPage]: null
        });
    }, [
        currentPage,
        loadedData
    ]);

    const deleteItem = useCallback(async (itemId) => {
        console.log('entrei em deleteItem');
        await api.delete(`items/${itemId}`);
        setSelectedItem(null);
        setLoadedData({
            ...loadedData,
            [currentPage]: null
        });
    }, [
        currentPage,
        loadedData
    ]);

    useEffect(() => {
        console.log('entrou no hook');
        fetchValues();
    }, [currentPage, baseDate, fetchValues]);

    return {
        fetchValues,
        spendingByMonth,
        currentPage,
        setCurrentPage,
        updateMonthData,
        bodyData,
        loading: loadingBody,
        fetchItemValue,
        selectedItem,
        updateItem,
        deleteItem,
    };
};

export default useReport;
