import styled from "styled-components";

export const GroupItensTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  &.generic-dream {
    gap: 0;
  }

  &.mb-32 {
    margin-bottom: 32px;
  }
`;