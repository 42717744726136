import React from 'react';
import { Wrapper, Container } from './styles';

export const RootContainer = ({ children, className }) => {
  return (
    <Wrapper className={className}>
      <Container>{children}</Container>
    </Wrapper>
  );
};
