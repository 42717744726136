import { Span } from 'components/atoms/Span';
import React from 'react';
import { Footer } from './styles';

export const DetailedExtractFooter = ({
  text = '',
  value = '',
  className = '',
}) => {
  return (
    <Footer className={className}>
      <Span className={className}>{text}</Span>
      <Span className={className}>{value}</Span>
    </Footer>
  );
};
