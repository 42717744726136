import React, { useEffect, useState } from 'react';
import { Modal } from 'components/templates/Modal';
import { getAllocationStrategy, getLiquidity } from 'utils/dreams';
import { GalleryModal } from 'components/organisms/GalleryModal';
import { formatDateToDisplay, formatDateToISO } from 'utils/date';
import { calculateIFP, getImageGoals } from 'utils/goals';
import toast from 'react-hot-toast';
import { genericDreamSchema } from 'utils/validator';

export const GenericDreamModal = ({
  showModal = '',
  setShowModal = () => { },
  reloadPage = () => { },
  action = '',
  dreamProps,
  onCreateGoal = () => { },
  onUpdateGoal = () => { },
  uploadFile = () => { },
}) => {
  const [name, setName] = useState(dreamProps?.name || '');
  const [description, setDescription] = useState(dreamProps?.description || '');
  const [noDefinedValue, setNoDefinedValue] = useState(
    dreamProps?.noDefinedValue || false
  );
  const [goalValue, setGoalValue] = useState(dreamProps?.value.raw || '');
  const [deadline, setDeadline] = useState(
    formatDateToISO(dreamProps?.end) || ''
  );
  const [allocationStrategy, setAllocationStrategy] = useState(
    dreamProps?.strategic_allocation || ''
  );
  const [liquidity, setLiquidity] = useState(dreamProps?.liquidity || '');
  const [currentFixedAssets, setCurrentFixedAssets] = useState(
    dreamProps?.patrimonial_active_value.raw || ''
  );
  const [currentFinancialAssets, setCurrentFinancialAssets] = useState(
    dreamProps?.financial_active_value.raw || ''
  );
  const [profitability, setProfitability] = useState(
    dreamProps?.profitability || ''
  );
  const [savedMoney, setSavedMoney] = useState(dreamProps?.saved_money.raw || '');
  const [showGaleryModal, setShowGaleryModal] = useState(false);
  const [ifpValue, setIfpValue] = useState(dreamProps?.ifp?.raw || '');
  const [image, setImage] = React.useState({
    backgroundImage: dreamProps?.url || getImageGoals() || '',
    externalUrl: undefined,
    file: undefined,
  });

  useEffect(() => {
    const fixedAssets = parseFloat(
      (currentFixedAssets || '0').toString().replace(',', '.')
    );
    const financialAssets = parseFloat(
      (currentFinancialAssets || '0').toString().replace(',', '.')
    );

    const formattedPatrimonialActiveValue = parseFloat(fixedAssets) || 0;
    const formattedFinancialActiveValue = parseFloat(financialAssets) || 0;

    const calculatedIFP = calculateIFP({
      end: deadline,
      profitability,
      value: goalValue || 0,
      patrimonialActiveValue: formattedPatrimonialActiveValue,
      financialActiveValue: formattedFinancialActiveValue,
    });

    setIfpValue(calculatedIFP);
    setSavedMoney(formattedPatrimonialActiveValue + formattedFinancialActiveValue);
  }, [
    deadline,
    profitability,
    goalValue,
    currentFixedAssets,
    currentFinancialAssets,
  ]);

  useEffect(() => {
    const formattedCurrentFixedAssets = parseFloat(currentFixedAssets) || 0;
    const formattedCurrentFinancialAssets = parseFloat(currentFinancialAssets) || 0;
    setSavedMoney(formattedCurrentFixedAssets + formattedCurrentFinancialAssets);
  }, [currentFixedAssets, currentFinancialAssets]);

  useEffect(() => {
    if (noDefinedValue) {
      setGoalValue(0);
      setCurrentFixedAssets(0);
      setCurrentFinancialAssets(0);
      setSavedMoney(0);
      setAllocationStrategy(null);
      setLiquidity(null);
      setProfitability(null);
    }
  }, [noDefinedValue])

  const validateFields = () => {
    let errors = {};

    try {
      genericDreamSchema.validateSync({
        name,
        description,
        deadline,
      });
    } catch (error) {
      errors = { ...errors, [error.path]: error.message };
    }

    return errors;
  };

  const handleSave = async () => {
    const errors = validateFields();

    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach(error => {
        toast.error(error);
      });
      return;
    }

    let fileId = undefined;

    if (image?.file) {
      const updatedImage = await uploadFile(image?.file);
      fileId = updatedImage?.id;
    }

    const goalData = {
      name,
      description,
      value: goalValue || 0,
      end: deadline,
      profitability,
      saved_money: savedMoney,
      strategic_allocation: allocationStrategy,
      strategic_allocation_bpl: dreamProps?.strategic_allocation_bpl || '',
      patrimonial_active_value: currentFixedAssets,
      financial_active_value: currentFinancialAssets,
      financial_active_value_bpl: dreamProps?.financial_active_value_bpl.raw || '',
      liquidity,
      liquidity_bpl: dreamProps?.liquidity_bpl?.raw || '',
      bpl: dreamProps?.bpl.raw || '',
      plf: dreamProps?.plf.raw || '',
      type: dreamProps?.type || '',
      file_id: fileId,
      external_url: image?.externalUrl,
    };

    try {
      if (action === 'edit') {
        const response = await onUpdateGoal(dreamProps?.id, goalData);
        if (response) {
          reloadPage(response);
        }
      } else {
        const response = await onCreateGoal(goalData);
        if (response) {
          reloadPage(response);
        }
      }
      setShowModal(false);
    } catch (error) {
      console.error(
        `Failed to ${action === 'edit' ? 'update' : 'create'} goal:`,
        error
      );
    }
  };

  const modalHeader = {
    edit: (
      <Modal.SimpleTitleContent
        title="Editar sonho"
        onClose={() => setShowModal(false)}
        type="edit"
        className="generic-dream edit"
      />
    ),
    add: (
      <Modal.SimpleTitleContent
        title="Adicionar sonho"
        onClose={() => setShowModal(false)}
        type="add"
        className="generic-dream add"
      />
    ),
  };

  return (
    <>
      {showGaleryModal ? (
        <GalleryModal
          showModal={showGaleryModal}
          setShowModal={setShowGaleryModal}
          onChange={setImage}
        />
      ) : (
        <Modal.Container
          showModal={showModal}
          setShowModal={setShowModal}
          className="generic-dream"
          extended={!noDefinedValue}
        >
          <Modal.TitleContainer>{modalHeader[action]}</Modal.TitleContainer>
          <Modal.BodyContainer className="generic-dream">
            <Modal.Section>
              <Modal.InputText
                label="Nome do sonho"
                getInputValue={setName}
                className="generic-dream-modal first"
                defaultValue={name}
              />
              <Modal.InputText
                label="Descrição"
                getInputValue={setDescription}
                className="generic-dream-modal after"
                defaultValue={description}
              />
              <Modal.InputImage
                className="generic-dream"
                backgroundImage={image?.backgroundImage || getImageGoals() || ''}
                onClick={() => setShowGaleryModal(true)}
              />
              <Modal.GroupInputs show={true} className="not-separate column-at-end">
                <Modal.DatePicker
                  className="generic-dream-modal generic-dream-modal-datepicker full-width after mt-32"
                  label="Prazo"
                  getInputValue={setDeadline}
                  placeholder={formatDateToDisplay(deadline)}
                  defaultValue={deadline}
                />
                <Modal.Switch
                  label="Sem valor definido"
                  className="generic-dream-modal after term"
                  getSwitchValue={setNoDefinedValue}
                  defaultValue={noDefinedValue}
                />
              </Modal.GroupInputs>
              {!noDefinedValue && (
                <Modal.InputCurrency
                  label="Valor total"
                  getInputValue={setGoalValue}
                  className="generic-dream-modal after"
                  defaultValue={goalValue}
                />
              )}
            </Modal.Section>
            {!noDefinedValue && (
              <Modal.Section className="separate">
                <Modal.GroupInputs show={true} className="generic-dream-modal">
                  <Modal.InputCurrency
                    label="Patrimônio Imobilizado atual"
                    getInputValue={setCurrentFixedAssets}
                    className="generic-dream-modal first half second-section"
                    defaultValue={currentFixedAssets}
                  />
                  <Modal.InputCurrency
                    label="Patrimônio Financeiro atual"
                    getInputValue={setCurrentFinancialAssets}
                    className="generic-dream-modal first half last second-section"
                    defaultValue={currentFinancialAssets}
                  />
                </Modal.GroupInputs>
                <Modal.InputCurrency
                  label="Valor total atual"
                  getInputValue={() => { }}
                  className="generic-dream-modal after second-section"
                  defaultValue={savedMoney}
                  disabled
                />
                <Modal.GroupInputs show={true} className="generic-dream-modal">
                  <Modal.Dropdown
                    label="Estratégia de alocação"
                    className="generic-dream-modal after half second-section normalizing-line-size"
                    selectOptions={getAllocationStrategy()}
                    getDropdownValue={setAllocationStrategy}
                    placeholderSelect={allocationStrategy}
                  />
                  <Modal.Dropdown
                    label="Liquidez"
                    className="generic-dream-modal after half last second-section normalizing-line-size"
                    selectOptions={getLiquidity()}
                    getDropdownValue={setLiquidity}
                    placeholderSelect={liquidity}
                  />
                </Modal.GroupInputs>
                <Modal.PercentageField
                  label="Rentabilidade"
                  getInputValue={value => setProfitability(value)}
                  className="generic-dream-modal after second-section"
                  defaultValue={profitability}
                />
                <Modal.InputCurrency
                  label="Investimento mensal"
                  getInputValue={() => { }}
                  className="generic-dream-modal after second-section"
                  defaultValue={ifpValue}
                  disabled
                />
              </Modal.Section>
            )}
          </Modal.BodyContainer>
          <Modal.FooterWithOneButton
            text="Confirmar"
            className="generic-dream-modal"
            onClick={handleSave}
          />
        </Modal.Container>
      )}
    </>
  );
};
