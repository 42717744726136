import React, { useState, useCallback, useEffect } from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { propertiesRentValidationSchema } from 'utils/validator';

const useRetirementPatrimonies = () => {
  console.log('entrou no hook');
  const { id: userId } = useSelector(state => state.user.profile);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchItems = useCallback(async () => {
    try {
      const { data: items } = await api.get(
        `users/${userId}/active_patrimonies/rent`
      );
      console.log('entrou aquiiii');
      setItems(items);
    } catch (error) {
      console.error('Error fetching retirement patrimonies:', error);
      toast.error('Ocorreu um erro ao buscar os patrimônios.');
    } finally {
      setLoading(false);
    }
  }, [userId]);
  useEffect(() => {
    console.log('entrou aquii');
    fetchItems();
  }, [fetchItems]);
  const reloadPatrimonies = useCallback(() => {
    fetchItems();
  }, [fetchItems]);

  return {
    items,
    setItems,
    loading,
    setLoading,
    fetchItems,
    reload: reloadPatrimonies,
  };
};

const useUpdateActivePatrimony = () => {
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const { id: userId } = useSelector(state => state.user.profile);

  const updateActivePatrimony = useCallback(
    async (activePatrimonyId, updatedData, setShowEditItemModal) => {
      if (!activePatrimonyId) {
        toast.error('ID do patrimônio inválido.');
        return null;
      }
      const validate = await propertiesRentValidationSchema(updatedData);

      if (!validate.success) {
        validate.error.forEach(message => {
          toast.error(message.message);
        });
        return;
      }
      setUpdating(true);
      setError(null);
      const updatePromise = api
        .put(`active_patrimonies/${activePatrimonyId}`, updatedData)
        .then(response => {
          setShowEditItemModal(false);
          return response.data;
        })
        .catch(err => {
          console.error('Erro ao atualizar o item:', err);
          setError(err);
          throw err;
        })
        .finally(() => {
          setUpdating(false);
        });

      await toast.promise(updatePromise, {
        loading: 'Atualizando item...',
        success: <b>Item atualizado com sucesso!</b>,
        error: <b>Erro ao atualizar o item.</b>,
      });

      return updatePromise;
    },
    // eslint-disable-next-line
    [userId]
  );

  return { updateActivePatrimony, updating, error };
};

export { useRetirementPatrimonies, useUpdateActivePatrimony };
