import React from 'react';
import { Label } from 'components/atoms/Label';
import { Span } from 'components/atoms/Span';
import { Dropdown } from 'components/atoms/Dropdown';

export const ModalDropdown = ({
  className,
  selectOptions,
  placeholderSelect,
  getDropdownValue,
  setDropdownValue,
  label,
}) => {
  return (
    <Label className={className}>
      <Span className={className}>{label}</Span>
      <Dropdown
        placeholder={placeholderSelect}
        options={selectOptions}
        getDropdownValue={getDropdownValue}
        setDropdownValue={setDropdownValue}
        className={className}
      />
    </Label>
  );
};
