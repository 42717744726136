import styled from 'styled-components';
import { CardAnimationIncreasing } from 'styles/animations/CardAnimationIncreasing';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';
import { NavigationCardBasicStyle } from 'styles/components/NavigationCardBasicStyle';

export const CardContainer = styled.div`
  &.budget {
    ${NavigationCardBasicStyle}
      
    &:hover {
      ${CardAnimationIncreasing}
    }

    &.selected-spending {
      background-color: ${({ theme }) => theme.blueSky};
    }
    &.selected-patrimony {
      background-color: ${({ theme }) => theme.blueRoyal};
    }
    &.selected-passive {
      background-color: ${({ theme }) => theme.redMedium};
    }
  }
  &.empyt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.83vw;
    padding: 1.3vw;
    width: 19.58vw;
    height: 13.33vw;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.blueRoyal};

    @media (max-width: 1600px) {
      width: 289px;
      height: 214px;
      padding: 23.7px;
      border-radius: 12px;
    }

    @media (max-width: 1370px) {
      width: 48%;
    }
  }
  &.spending-table {
    display: block;
    background-color: ${({ theme }) => theme.white};
    width: 100%;
    padding-top: 24px;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 22px;
    margin: 5px auto 24px;
    border-radius: 16px;

    &.no-footer {
      padding-bottom: 24px;
    }
    &.grafic {
      height: auto;
    }

    @media (max-width: 1600px) {
      margin: 5px auto 18px;
    }

    @media (max-width: 1440px) {
      padding-top: 18px;
    }

    @media (max-width: 1280px) {
      overflow-x: scroll;
    }
  }
  &.card-table {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    background-color: ${({ theme }) => theme.white};
    width: 100%;
    padding-top: 24px;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 10px;
    border-radius: 16px;
    height: max-content;

    @media (max-width: 1440px) {
      padding-top: 18px;
    }

    @media (max-width: 1280px) {
      overflow-x: scroll;
    }

    ::-webkit-scrollbar {
      width: 10px;
      height: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    @media (max-width: 1875px) {
      flex-direction: column-reverse;
      gap: 24px;
    }
  }
  &.card-table-grafic {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }
  &.table-recom {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    min-width: 300px;
    @media (max-width: 1325px) {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        height: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.graySilver};
        border-radius: 50px;
      }

      &::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.grayPale};
        border-radius: 50px;
      }

      &::-webkit-scrollbar-button {
        display: none;
      }
    }
  }
  &.no-footer {
    padding-bottom: 24px;
  }
  &.pot-table {
    display: flex;
    align-items: flex-end;
    width: 45%;
    height: auto;

    @media (max-width: 1740px) {
      width: 100%;
      height: 412px;
      padding-bottom: 32px;
      gap: 36px;
    }
  }
  &.grafic-table {
    display: flex;
    width: 60%;
  }
  &.table-grafic {
    display: flex;
    width: 60%;
    height: 100%;

    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  &.loading {
    overflow-x: hidden;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
  }
  &.progress-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 784px;
    height: 256px;
    background-color: ${({ theme }) => theme.white};
    border-radius: 16px;
    box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.blackWithSlowOpacity};
    padding: 30px;
    min-width: 320px;
    min-height: 136.53px;
  }
  &.pot-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1600px;
    height: auto;
    margin-bottom: 20px;
    background-color: ${({ theme }) => theme.white};
    border-radius: 16px;
    box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.blackWithSlowOpacity};
    padding: 30px;
  }
  &.card-detail {
    display: flex;
    justify-content: space-between;
    margin-top: 91px;

    @media (max-width: 1024px) {
      margin-top: 20px;
      flex-direction: column;
      gap: 20px;
    }
  }

  &.carousel-report {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 88px;
    min-height: 40px;
    border-radius: 8px;

    &.spendings {
      background-color: ${({ theme }) => theme.blueSky};
    }
    &.receipt {
      background-color: ${({ theme }) => theme.blueRoyal};
    }
    &.investment {
      background-color: ${({ theme }) => theme.greenDark};
    }
  }
  &.report-table {
    display: relative;
    margin-top: 24px;
    background-color: ${({ theme }) => theme.white};
    width: 1600px;
    padding-top: 24px;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 22px;
    margin: 32px auto 24px;
    border-radius: 16px;

    @media (max-width: 1440px) {
      padding-top: 18px;
    }
  }
`;
export const CardBody = styled.div`
  &.budget {
    width: max-content;
    height: 192px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.loading {
      display: none;
    }
  }
`;

export const CardTitle = styled.div`
  &.spending-card {
    width: max-content;
  }
`;
export const CardContent = styled.div`
  &.budget {
    display: flex;
    justify-content: space-between;
    width: auto;
    align-items: center;
    padding-bottom: 14px;

    @media (max-width: 1845px) {
      padding-bottom: 5px;
    }
  }
  &.carousel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 119px;
    height: 49px;
    min-width: 80px;
  }
  &.right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  &.margin-min {
    margin-top: 5px;
  }
  &.margin-max {
    margin-top: 10px;
    height: 57px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1845px) {
      margin-top: 0;
      height: 42px;
    }
  }
  &.loading {
    display: none;
  }
`;
export const AmountOfMoneyContainer = styled.div``;
export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;
