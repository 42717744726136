import React from 'react';
import { Container } from './styles';
import { Table } from 'components/templates/Tables';
import PencilIcon from 'components/atoms/icons/PencilIcon';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { RecurrentIcon } from 'components/atoms/icons/RecurrentIcon';
import {
  buildInstallment,
  buildEntry,
  buildInvestment,
} from 'services/builders';
export const SimpleExtractCategoryItemsTable = ({
  entries = [],
  loading = true,
  setItemData = () => {},
  setShowEditItemModal = () => {},
}) => {
  const handleEditClick = entry => {
    if (!entry?.editable) return;

    let buildedData = {};

    if (entry?.classification === 'investments') {
      buildedData = buildInvestment(entry);
      setItemData(buildedData);
      return setShowEditItemModal(prev => !prev);
    }

    if (entry.type === 'Parcelado') {
      buildedData = buildInstallment(entry);
      setItemData(buildedData);
      return setShowEditItemModal(prev => !prev);
    }
    buildedData = buildEntry(entry, true);
    setItemData(buildedData);
    setShowEditItemModal(prev => !prev);
  };
  if (loading) {
    return (
      <Container className={`table ${loading ? 'loading' : ''}`}>
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <GenericLoading key={index} className="extract-item-body" />
          ))}
      </Container>
    );
  }
  return (
    <Container className="table">
      <Table.Container className="extract-item" key={Math.random()}>
        <Table.TableBasic className="extract-table">
          <Table.Thead>
            <Table.Tr className="extract-item-title">
              <Table.Th className="extract-item"></Table.Th>
              <Table.Th className="extract-item name">Item</Table.Th>
              <Table.Th className="extract-item center"></Table.Th>
              <Table.Th className="extract-item center">Valor</Table.Th>
              <Table.Th className="extract-item center">Pagamento</Table.Th>
              <Table.Th className="extract-item center">Parcela</Table.Th>
              <Table.Th className="extract-item center">Data</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {entries.map((entry, index) => (
              <Table.Tr className="extract-item-body" key={index}>
                <Table.Td
                  className="extract-item"
                  onClick={() => handleEditClick(entry)}
                >
                  <PencilIcon
                    className={`extract--category-item ${!entry.editable &&
                      'disable'}`}
                  />
                </Table.Td>
                <Table.Td className="extract-item" colSpan={2}>
                  {entry?.installment_name || entry?.description}
                </Table.Td>
                <Table.Td className="extract-item center">
                  {entry?.value.formatted}
                </Table.Td>
                <Table.Td className="extract-item center">
                  {entry?.type}
                </Table.Td>
                <Table.Td className="extract-item center">
                  {entry?.recurrent ? <RecurrentIcon /> : entry?.installments}
                </Table.Td>
                <Table.Td className="extract-item center">
                  {entry?.date_start.formatted
                    ? entry?.date_start.formatted
                    : entry?.date_start}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.TableBasic>
      </Table.Container>
    </Container>
  );
};
