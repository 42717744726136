import React from 'react';
import { Label } from 'components/atoms/Label';
import { Input } from 'components/atoms/Input';
import { Span } from 'components/atoms/Span';

export const ModalInputText = ({
  className,
  getInputValue,
  placeholder,
  label,
  defaultValue = '',
  disabled = false,
}) => {
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    getInputValue(inputValue);
  }, [getInputValue, inputValue]);

  React.useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  return (
    <Label className={className}>
      <Span className={className}>{label}</Span>
      <Input
        className={`${className} ${disabled ? 'disabled' : ''}`}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Label>
  );
};
