import styled, { keyframes } from "styled-components";

const slideInFromRight = keyframes`
    0% {
        transform: translateX(10%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;

export const InputContainer = styled.div`
    &.main {
        cursor: pointer;
        height: 40px;
        font-size: 0.8rem;
        border: 1px solid ${props => props.theme.blueMidnight};
        outline: none;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: right;
        padding: 12px;
        gap: 16px;
        animation: ${slideInFromRight} 0.5s ease forwards;
    }

     @media (max-width: 1440px){
         &.main {
            width: 220px;
            height: 38px;
            font-size: 0.7rem;
            gap: 12px;
            padding: 10px;
         }
     }

     @media (max-width: 1200px){
        &.main {
            width: 100%;
        }
    }

     @media (max-width: 1024px){
        &.main {
            width: 220px;
        }
    }

    &.main > .detailed-extract-search-icon {
        font-size: 0.8rem;
    }
`;


export const IconContainer = styled.div`
    &.detailed-extract-search-icon {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid ${props => props.theme.blueMidnight};
        border-radius: 8px;

        &:hover {
            background-color: ${props => props.theme.sideBarHover};
            border: none;

            .detailed-extract-search-icon {
                color: ${props => props.theme.white};
            }
        }

        @media (max-width: 1440px){
            width: 38px;
            height: 38px;
        }
    }

    &.detailed-extract-search-icon > .detailed-extract-search-icon {
        font-size: 16px;
    }
`
