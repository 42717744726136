import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api from 'services/api';
import toast from 'react-hot-toast';
import useDebounce from './useDebounce';
import { entryValidationSchema } from 'utils/validator';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  formatDateISO,
} from 'utils/date';
const useFetchEntriesByCategoryType = (
  initialCategoryType = null,
  start = new Date(),
  end = new Date(),
  inputSearch = '',
  categoryType
) => {
  const [entries, setEntries] = useState([]);
  const [loadings, setLoadings] = useState({
    loadingEntries: false,
    loadingEntriesMore: false,
  });
  const [error, setError] = useState(null);
  const { id: userId } = useSelector(state => state.user.profile);
  const [page, setPage] = useState(1);
  const [totalEntriesSearch, setTotalEntriesSearch] = useState(0);

  const debouncedInputSearch = useDebounce(inputSearch, 500);

  useEffect(() => {
    setPage(1);
  }, [categoryType]);

  const updateLoadingState = useCallback(isLoadingMore => {
    setLoadings({
      loadingEntries: !isLoadingMore,
      loadingEntriesMore: isLoadingMore,
    });
  }, []);

  const getApiParams = useCallback(
    (categoryType, startDate, endDate) => ({
      start: formatDateISO(getFirstDayOfMonth(new Date(startDate))),
      end: formatDateISO(getLastDayOfMonth(new Date(endDate))),
      category_type: categoryType,
      limit: 10,
      page,
      inputSearch: debouncedInputSearch,
    }),
    [debouncedInputSearch, page]
  );

  const handleApiError = useCallback(err => {
    console.error('Error fetching entries:', err);
    setError(err);
    toast.error('Ocorreu um erro ao buscar os dados dos items.');
  }, []);

  const fetchEntries = useCallback(
    async (
      categoryType = initialCategoryType,
      startDate = start,
      endDate = end,
      resetPage = false
    ) => {
      if (!categoryType || categoryType === 'investments') {
        return [];
      }
      const isLoadingMore = resetPage ? false : true;
      updateLoadingState(isLoadingMore);
      setError(null);

      try {
        const { data: fetchedEntries } = await api.get(
          `entries/${userId}/budget-by-category-type-detail`,
          {
            params: getApiParams(categoryType, startDate, endDate),
          }
        );

        setEntries(prevEntries =>
          resetPage
            ? fetchedEntries.entries
            : [...prevEntries, ...fetchedEntries.entries]
        );
        const totalValue = fetchedEntries.total?.formatted ?? null;
        setTotalEntriesSearch(totalValue);

        return fetchedEntries;
      } catch (err) {
        handleApiError(err);
        return [];
      } finally {
        setLoadings({ loadingEntries: false, loadingEntriesMore: false });
      }
    },
    // eslint-disable-next-line
    [
      userId,
      initialCategoryType,
      start,
      end,
      getApiParams,
      updateLoadingState,
      handleApiError,
      debouncedInputSearch,
    ]
  );

  const reloadEntries = useCallback(() => {
    setPage(1);
    setEntries([]);
    fetchEntries(initialCategoryType, start, end, true);
    // eslint-disable-next-line
  }, [fetchEntries, initialCategoryType, start, end, categoryType]);

  useEffect(
    () => {
      if (debouncedInputSearch.length > 0) {
        setEntries([]);
        setPage(1);
      }
      fetchEntries(initialCategoryType, start, end, page === 1);
    },
    // eslint-disable-next-line
    [
      page,
      start,
      end,
      debouncedInputSearch,
      categoryType,
    ]
  );

  return {
    entries,
    loadings,
    error,
    fetchEntries,
    page,
    setPage,
    isSearchActive: debouncedInputSearch.length > 0,
    totalEntriesSearch,
    reloadEntries,
  };
};

const useFetchEntries = (itemId = null, start, end) => {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id: userId } = useSelector(state => state.user.profile);

  const fetchEntries = useCallback(
    async (itemId = null) => {
      if (!itemId) {
        return [];
      }
      setLoading(true);
      setError(null);

      try {
        const { data: fetchedEntries } = await api.get(
          `/entries/${userId}/budget-detail`,
          {
            params: {
              start: formatDateISO(getFirstDayOfMonth(new Date(start))),
              end: formatDateISO(getLastDayOfMonth(new Date(end))),
              item_id: itemId,
            },
          }
        );
        setEntries(fetchedEntries);
        return fetchedEntries;
      } catch (err) {
        console.error('Error fetching entries:', err);
        setError(err);
        toast.error('Ocorreu um erro ao buscar os dados dos items.');
        return [];
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [userId, start, end, itemId]
  );

  useEffect(
    () => {
      if (itemId) {
        fetchEntries(itemId);
      }
    },
    // eslint-disable-next-line
    [start, end, itemId]
  );

  const reload = useCallback(() => {
    fetchEntries(itemId);
    // eslint-disable-next-line
  }, [itemId, start, end]);
  return { entries, loading, error, fetchEntries, reload };
};
const useUpdateEntry = () => {
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);

  const updateEntry = useCallback(
    async (entryId, updatedData, setShowEditItemModal) => {
      if (!entryId) {
        toast.error('ID do item inválido.');
        return null;
      }

      const validate = await entryValidationSchema(updatedData);

      if (!validate.success) {
        validate.error.forEach(message => {
          toast.error(message.message);
        });
        return;
      }

      setUpdating(true);
      setError(null);

      const updatePromise = api
        .put(`/entries/${entryId}`, updatedData)
        .then(response => {
          setShowEditItemModal(false);
          return response.data;
        })
        .catch(err => {
          console.error('Erro ao atualizar o item:', err);
          setError(err);
          throw err;
        })
        .finally(() => {
          setUpdating(false);
        });

      await toast.promise(updatePromise, {
        loading: 'Atualizando item...',
        success: <b>Item atualizado com sucesso!</b>,
        error: <b>Erro ao atualizar o item.</b>,
      });

      return updatePromise;
    },
    []
  );

  return { updateEntry, updating, error };
};

const useDeleteEntry = () => {
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(null);

  const deleteEntry = useCallback(async (entryId, onSuccess) => {
    if (!entryId) {
      toast.error('ID do item inválido.');
      return null;
    }

    setDeleting(true);
    setError(null);

    const deletePromise = api
      .delete(`/entries/${entryId}`)
      .then(response => {
        if (onSuccess) {
          onSuccess();
        }
        return response.data;
      })
      .catch(err => {
        console.error('Erro ao deletar o item:', err);
        setError(err);
        throw err;
      })
      .finally(() => {
        setDeleting(false);
      });

    await toast.promise(deletePromise, {
      loading: 'Deletando item...',
      success: <b>Item deletado com sucesso!</b>,
      error: <b>Erro ao deletar o item.</b>,
    });

    return deletePromise;
  }, []);

  return { deleteEntry, deleting, error };
};
export {
  useFetchEntriesByCategoryType,
  useFetchEntries,
  useUpdateEntry,
  useDeleteEntry,
};
