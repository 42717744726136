import styled from 'styled-components';
import { CardAnimationIncreasing } from 'styles/animations/CardAnimationIncreasing';
import { NavigationCardBasicStyle } from 'styles/components/NavigationCardBasicStyle';

export const Container = styled.div`
  ${NavigationCardBasicStyle}
  
  &:hover {
    ${CardAnimationIncreasing}
  }

  &.selected {
    background-color: ${({ theme }) => theme.blueNavy};
  }
`;

export const Card = styled.div`
  width: max-content;
  height: 10.00vw;
  gap: 1.82vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1600px) {
    height: 160px;
    gap: 32px;
  }
`;

export const TitleContainer = styled.div`
  width: max-content;
`;
