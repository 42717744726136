import styled from 'styled-components';

export const DownArrowContainer = styled.div`
  &.default {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: ${props => props.theme.blueRoyal};
  }
`;

export const StyledDownArrow = styled.svg`
  &.default {
    width: 20px;
    height: 20px;
    padding: 4px;

    path {
      stroke: ${props => props.theme.white};
    }
  }
`;
