import React, { useEffect } from 'react';
import { Span } from 'components/atoms/Span';
import { DateDropdown } from 'components/molecules/DateDropdown';
import { ExtractMenuRedirect } from 'components/molecules/ExtractMenuRedirect';
import { Container, GroupDropdown, GroupItens } from './styles';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { getCurrentMonthObject } from 'utils/date';

export const ExtractMenu = ({
  currentPage = '',
  setCurrentPage = () => {},
  loading = false,
  startDate = getCurrentMonthObject(new Date()),
  setStartDate = () => {},
  endDate = getCurrentMonthObject(new Date()),
  setEndDate = () => {},
}) => {
  useEffect(() => {
  }, [startDate, endDate]);

  if (loading) {
    return (
      <Container>
        <GroupItens className="title">
          <GenericLoading className="extract-menu-header" />
          <ExtractMenuRedirect
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={loading}
          />
        </GroupItens>
        <GroupDropdown>
          <GenericLoading className="extract-menu-dropdown" />
          <GenericLoading className="extract-menu-dropdown" />
        </GroupDropdown>
      </Container>
    );
  }

  return (
    <Container>
      <GroupItens className="title">
        <Span className="extract-menu-header">Extrato</Span>
        <ExtractMenuRedirect
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </GroupItens>
      <GroupDropdown>
        <DateDropdown
          className="extract first"
          prefix="de"
          selectedDate={startDate}
          setSelectedDate={setStartDate}

        />
        <DateDropdown
          className="extract first"
          prefix="até"
          selectedDate={endDate}
          setSelectedDate={setEndDate}
          getLastDay
        />
      </GroupDropdown>
    </Container>
  );
};
