import React from 'react';
import { ModalFooter } from './styles';
import { Button } from 'components/atoms/Button';

export const ModalFooterThreeButtons = ({
  className,
  onCancel,
  onConfirm,
  onQuit,
  cancelButtonText = 'Confirmar',
  confirmButtonText = 'Cancelar',
  quitButtonText = 'Quitar',
}) => {
  return (
    <ModalFooter>
      <Button className={`${className} cancel`} onClick={onConfirm}>
        {cancelButtonText}
      </Button>
      <Button className={`${className} confirm`} onClick={onQuit}>
        {quitButtonText}
      </Button>
      <Button className={`${className} confirm`} onClick={onCancel}>
        {confirmButtonText}
      </Button>
    </ModalFooter>
  );
};
