import { useState, useCallback, useContext } from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GeneralContext } from 'utils/contexts/GeneralContext';

const defaultCard = {
  financial_patrimony: {
    raw: 0,
    formatted: "R$ 0"
  },
  material_patrimony: {
    raw: 0,
    formatted: "R$ 0"
  },
  sealed_value: {
    raw: 0,
    formatted: "R$ 0"
  },
  investments_value: {
    raw: 0,
    formatted: "R$ 0"
  }
};

const defaultBalance = {
  financial: {
    raw: 0,
    formatted: "R$ 0"
  },
  patrimonial: {
    raw: 0,
    formatted: "R$ 0"
  }
};

const useGoals = () => {
  const { id: userId } = useSelector(state => state.user.profile);
  const { setLoading } = useContext(GeneralContext);
  const [goals, setGoals] = useState([]);
  const [card, setCard] = useState(null);
  const [financialBalance, setFinancialBalance] = useState(null);
  const [patrimonialBalance, setPatrimonialBalance] = useState(null);
  const [loadingBody, setLoadingBody] = useState(true);

  const updateLoading = useCallback((value) => {
    setLoading(value);
    setLoadingBody(value);
  }, [setLoading])

  const fetchGoals = useCallback(async () => {
    try {
      if (!userId) {
        toast.error('Usuário não encontrado.');
        return;
      }

      const response = await api.get(`users/${userId}/goals`, {
        params: {
          overview: true,
          formated_value: true
        },
      });
      const goalsList = response.data;
      setGoals(goalsList.goals || []);
      setCard(goalsList.card || defaultCard);
      return true;
    } catch (error) {
      setGoals([]);
      setCard(defaultCard);
      return false;
    }
  }, [userId]);

  const fetchBalances = useCallback(async () => {
    try {
      const { data: balance } = await api.get(`goals/${userId}/seals/balance`, {
        params: {
          formated_value: true
        },
      });
      setFinancialBalance(balance.financial || defaultBalance.financial);
      setPatrimonialBalance(balance.patrimonial || defaultBalance.patrimonial);
      return true;
    } catch (error) {
      setFinancialBalance(defaultBalance.financial);
      setPatrimonialBalance(defaultBalance.patrimonial);
      return false;
    }
  }, [userId]);

  const createGoal = async goalData => {
    try {
      await api.post(
        `users/${userId}/goals`,
        goalData
      );
      toast.success('Meta criada com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao criar o sonho.');
    }
  };

  const updateGoal = async (goalId, updatedData) => {
    try {
      await api.put(
        `goals/${goalId}`,
        updatedData
      );
      toast.success('Sonho atualizado com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar o sonho.');
    }
  };

  const deleteGoal = async goalId => {
    try {
      await api.delete(`goals/${goalId}`);
      setGoals(prevGoals => prevGoals.filter(goal => goal.id !== goalId));
      toast.success('Sonho excluído com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao excluir o sonho.');
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const fileResponse = await api.post('/files', formData);
    return fileResponse.data;
  }

  return {
    goals,
    loading: loadingBody,
    setLoading: updateLoading,
    card,
    financialBalance,
    patrimonialBalance,
    createGoal,
    updateGoal,
    deleteGoal,
    fetchGoals,
    fetchBalances,
    uploadFile,
  };
};

export default useGoals;
