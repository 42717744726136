import React, { useEffect, useState } from 'react';
import { SwitchContainer, Slider, Input } from './styles';

const Switch = ({
  checked,
  getValue = () => {},
  onChange,
  onClick,
  className,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    getValue(newChecked);
    onChange && onChange(newChecked);
  };

  return (
    <SwitchContainer onClick={onClick} className={className}>
      <Input type="checkbox" checked={isChecked} onChange={handleChange} />
      <Slider />
    </SwitchContainer>
  );
};

export default Switch;
