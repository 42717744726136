import styled from "styled-components";

export const PageContainer = styled.div`
  padding-left: 5%;
  padding-right: 32px;
  overflow: auto;
  width: 95vw;

  @media (max-width: 1500px) {
    padding-top: 0;
  }
`;