import React from 'react';
import { ImageIcon } from 'components/atoms/icons/ImageIcon';
import { InputImageContainer } from './styles';

export const ModalInputImage = ({
  className = '',
  backgroundImage = '',
  onClick = () => { },
}) => {

  return (
    <InputImageContainer
      className={className}
      backgroundImage={backgroundImage}
      onClick={onClick}
    >
      <ImageIcon className={className} />
    </InputImageContainer>
  );
};
