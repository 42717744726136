import styled from "styled-components";

export const GoalsPanelContainer = styled.div`
    padding-bottom: 32px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1.3%;

    @media (max-width: 1370px) {
      justify-content: space-between;
    }
    
    &.loading {
      gap: 0;
      justify-content: space-between;
    }
`;
