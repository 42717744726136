import * as React from 'react';
import { SVG } from './styles';

export const RecurrentIcon = props => {
  return (
    <SVG
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 256 256"
      enable-background="new 0 0 256 256"
    >
      <g>
        <g>
          <g>
            <path
              fill="#000000"
              d="M113.1,13.7c-21.4,2.2-44,11.5-60.1,24.7c-13.5,11-23.9,24-31.4,39.4c-15.6,31.8-15.6,68.8,0.1,100.7c22.4,45.8,72.3,71.4,122.4,62.8c37.2-6.4,68.3-30,84.7-64.3c1.7-3.6,3.2-7.1,3.2-7.8c0.1-1-2-3.4-8.9-10l-9-8.7l-1.6,5.2c-10,32.1-36.9,55.9-70.6,62.7c-9.1,1.8-24.8,1.8-34.1,0c-8.7-1.7-15.8-4.1-24-8.2c-25.4-12.6-43.6-36.6-49.1-64.7c-3.9-19.7-1-40.2,8.1-58.5c4.5-9.1,9.8-16.5,17.2-23.9c18.3-18.3,42.7-27.9,68.4-26.8c29.9,1.2,56.5,16.1,73.1,40.9c3.4,5.2,9.1,16.2,9.1,17.8c0,0.7-5.5,3-11.9,4.9c-1.4,0.4-2.5,1-2.5,1.2c0,0.2,8.1,7.6,18,16.4c19,16.9,21.7,19.3,22,19c0.1-0.1,0.7-3.4,1.5-7.4c0.7-4,1.6-7.4,1.8-7.5c0.3-0.2,0.4-0.6,0.2-1c-0.2-0.4,4.8-29.4,6.3-36.2c0.3-1.3,0.1-1.2-6.4,1c-3.6,1.3-6.7,2.2-6.8,2.1c-0.1-0.1-0.9-1.8-1.7-3.9C211.7,37.3,163.2,8.5,113.1,13.7z"
            />
          </g>
        </g>
      </g>
    </SVG>
  );
};
