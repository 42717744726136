import React from 'react';
import { HomeHeader } from './Home';
import { GoalsPanelHeader } from './GoalsPanelHeader';
import { AssetManagementHeader } from './AssetManagementHeader';
import { MapHeaderBasic } from './MapHeaderBasic';
import { RetirementHeader } from './RetirementHeader';
import { useLocation } from 'react-router-dom';

export const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const headersGroups = {
    '/home': <HomeHeader />,
    '/goals/goalPanel': <GoalsPanelHeader />,
    '/finances/patrimony/planning': <AssetManagementHeader />,
    '/finances/patrimony/map': <AssetManagementHeader />,
    '/finances/patrimony/act': <AssetManagementHeader />,
    '/finances/budget': <MapHeaderBasic />,
    '/management/report': <MapHeaderBasic />,
    '/management/spending': <MapHeaderBasic />,
    '/management/extract': <MapHeaderBasic className="extract" />,
    '/management/detail': <MapHeaderBasic />,
    '/retirement/map': <RetirementHeader />,
    '/retirement/planning': <RetirementHeader />,
  };

  return headersGroups[pathname] || <></>;
};
