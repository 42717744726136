import React from 'react';
import { Container } from './styles';
import { Span } from 'components/atoms/Span';
import { SearchBox } from 'components/molecules/SearchBox';
import { GenericLoading } from 'components/atoms/GenericLoading';

export const DetailedExtractMenu = ({
  loading = false,
  inputSearch = '',
  setInputSearch = () => {},
  placeholder = '',
}) => {
  if (loading) {
    return (
      <Container className="main">
        <GenericLoading className="extract-menu-dropdown" />
        <GenericLoading className="extract-redirect-icons" />
      </Container>
    );
  }

  return (
    <Container className="main">
      <Span className="extract-menu-header">Extrato Detalhado</Span>
      <SearchBox
        text={inputSearch}
        setText={setInputSearch}
        placeholder={placeholder}
      />
    </Container>
  );
};
