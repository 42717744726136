import styled from "styled-components";

export const Container = styled.div`
    &.gallery-modal {
        width: 111px;
        height: 82px;
        padding-left: 28px;
        padding-top: 20px;
        padding-bottom: 21px;
        padding-right: 27px;
        border-radius: 8px;
        border: 1px dotted ${props => props.theme.graySilver};
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
`;

export const Group = styled.div`
    &.gallery-modal {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 56px;
        height: 41px;
    }
`;