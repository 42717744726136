import React, { useEffect } from 'react';
import DirecionalButton from 'components/organisms/Direcional';
import { ReportSpendingTable } from 'components/organisms/ReportSpendingTable';
import SemiannualReportTitle from 'components/molecules/SemiannualReportTitle';
import { CarouselReport } from 'components/molecules/CarouselReport';
import {
  PageContainer,
} from './styles';
import useReport from 'hooks/useReport';
import toast from 'react-hot-toast';

export default function Report() {
  const {
    bodyData,
    spendingByMonth,
    currentPage,
    setCurrentPage,
    updateMonthData,
    loading,
    fetchItemValue,
    selectedItem,
    updateItem,
    fetchValues,
    deleteItem,
  } = useReport();

  useEffect(() => {
    if (!loading && bodyData.length === 0) {
      toast.error('Não foi possivel carregar os dados da página!');
    }
  }, [
    loading,
    bodyData
  ]);

  return (
    <PageContainer>
      <SemiannualReportTitle
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        updateMonthData={updateMonthData}
      />
      <CarouselReport
        currentPage={currentPage}
        months={spendingByMonth}
        updateMonthData={updateMonthData}
      />
      <ReportSpendingTable
        currentPage={currentPage}
        data={bodyData}
        loading={loading}
        fetchItemValue={fetchItemValue}
        selectedItem={selectedItem}
        updateItem={updateItem}
        reloadPage={fetchValues}
        deleteItem={deleteItem}
      />
      <DirecionalButton />
    </PageContainer>
  )
}
