import React, { Fragment } from 'react';
import {
  Container,
  Wrapper,
  IconContainer,
  ChevronIcon,
  CategoryContainer,
} from './styles';
import { Span } from 'components/atoms/Span';
import CategoryProgressBar from 'components/molecules/CategoryProgressBar';
import { FaChevronDown } from 'react-icons/fa';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { SimpleExtractCategoryItemsTable } from 'components/molecules/SimpleExtractCategoryItemsTable';
import { getCategoryIconByClassification } from './utils';

export const CategoryIntroduction = ({
  categories = [],
  setItems = () => {},
  currentPage = 'spendings',
  setShowEditItemModal = () => {},
  setItemData = () => {},
  selectedCategoryId = 0,
  setSelectedCategoryId = () => {},
  intervalDate = { start: new Date(), end: new Date() },
  loadingCategories = false,
  itemsByCategory = [],
  fetchItems = () => {},
  loadingItems = false,
  selectedItemId = 0,
  setSelectedItemId = () => {},
  fetchEntriesByItemId = () => {},
  entriesByItemId = [],
  loadingEntries = false,
  setSelectedEntryId = () => {},
  handleUpdate = () => {},
}) => {
  const handleExpandItemsOfCategory = async id => {
    const categoryId = id;
    setSelectedCategoryId(prevId => {
      if (prevId === id) {
        return null;
      } else {
        fetchItems(categoryId, intervalDate.start, intervalDate.end);
        return categoryId;
      }
    });
  };

  const handleFetchEntries = id => {
    const itemId = id;
    setSelectedItemId(prevId => {
      if (prevId === id) {
        return null;
      } else {
        fetchEntriesByItemId(itemId, intervalDate.start, intervalDate.end);
        return itemId;
      }
    });
  };

  const renderItems = categoryItems => {
    return categoryItems.map((item, index) => (
      <Fragment key={item.id}>
        <Container
          className={`items ${
            item.category_id === selectedCategoryId ? 'expanded' : ''
          }`}
          key={index}
        >
          <Container className="category-items">
            <Wrapper className="extract-title-item">
              <Span className="extract-item">{item.name}</Span>
            </Wrapper>
            <Wrapper className="extract-title-item">
              <Span className="extract-title-item">
                {item.accomplished.formatted}
                <Span className={'extract-title-item secondary'}>
                  {' de ' + item.estimated.formatted}
                </Span>
              </Span>
            </Wrapper>
          </Container>
          <Container className="body-items" key={item}>
            <CategoryProgressBar
              currentPage={currentPage}
              exceededPercentage={item.progress.exceeded}
              darkBluePercentage={item.progress.receipts}
              redTooltip="Progresso de falhas"
              blueTooltip="Progresso de sucesso"
              bluePercentage={item.progress.spendings}
              pinkPercentage={item.progress.installments}
              darkGreenPercentage={item.progress.investmentProgress}
              className="extract-category-item"
            />
            <IconContainer
              className="extract-category-item"
              onClick={() => handleFetchEntries(item.id)}
            >
              <ChevronIcon isRotated={item.id === selectedItemId}>
                <FaChevronDown size={9} />
              </ChevronIcon>
            </IconContainer>
          </Container>
        </Container>
        {selectedItemId === item.id && (
          <SimpleExtractCategoryItemsTable
            entries={entriesByItemId}
            loading={loadingEntries}
            setSelectedEntryId={setSelectedEntryId}
            setItemData={setItemData}
            itemData={item}
            handleUpdate={handleUpdate}
            setShowEditItemModal={setShowEditItemModal}
          />
        )}
      </Fragment>
    ));
  };

  const loadingItemsList = Array.from({ length: 5 }, (_, index) => (
    <Container
      className={`items ${loadingItems ? 'expanded' : ''} loading`}
      key={index}
    >
      <Container className="body-items">
        <GenericLoading className="extract-item-progressbar" />
      </Container>
    </Container>
  ));

  return (
    <>
      {categories.map((category, index) => (
        <CategoryContainer
          className={index > 0 ? 'after' : ''}
          key={category.id}
        >
          <Fragment>
            <Container
              className="header"
              onClick={() => handleExpandItemsOfCategory(category.id)}
            >
              <Wrapper className="extract-title">
                <IconContainer className="extract-title">
                  {getCategoryIconByClassification(
                    category.classification,
                    category.name
                  ) || <></>}
                </IconContainer>
                <Span className="extract-title">{category.name}</Span>
              </Wrapper>
              <Wrapper className="extract-title">
                <Span className="extract-title">
                  {category.accomplished.formatted}
                  <Span className={'extract-title secondary'}>
                    {' de ' + category.estimated.formatted}
                  </Span>
                </Span>
              </Wrapper>
            </Container>
            <Container className="body">
              <CategoryProgressBar
                currentPage={currentPage}
                exceededPercentage={category.progress.exceeded}
                bluePercentage={category.progress.spendings}
                pinkPercentage={category.progress.installments}
                darkBluePercentage={category.progress.receipts}
                darkGreenPercentage={category.progress.investmentProgress}
                redTooltip="Progresso de falhas"
                blueTooltip="Progresso de sucesso"
                className="extract-category"
              />
              <IconContainer
                className="extract-category"
                onClick={() => handleExpandItemsOfCategory(category.id)}
              >
                <ChevronIcon isRotated={selectedCategoryId === category.id}>
                  <FaChevronDown size={9} />
                </ChevronIcon>
              </IconContainer>
            </Container>
            {selectedCategoryId === category.id &&
              loadingItems &&
              loadingItemsList}
            {selectedCategoryId === category.id &&
              !loadingItems &&
              renderItems(itemsByCategory)}
          </Fragment>
        </CategoryContainer>
      ))}
    </>
  );
};
