import React from "react";
import { Header } from "components/templates/Header";
import { GeneralContext } from "utils/contexts/GeneralContext";
import { GenericLoading } from "components/atoms/GenericLoading";

export const GoalsPanelHeader = () => {

    const { loading } = React.useContext(GeneralContext);

    if (loading) {
        return (
            <Header.RootContainer className="full">
                <GenericLoading className="goals-panel-title" />
                <GenericLoading className="goals-panel-searchbar" />
            </Header.RootContainer>
        )
    }

    return (
        <Header.RootContainer className="full">
            <Header.GroupItens>
                <Header.Title
                    text="Painel de Sonhos"
                    className="map-header gray-silver"
                />
            </Header.GroupItens>
            <Header.GroupItens className="input-side">
                <Header.SearchBar
                    className="header"
                    placeholder="Pesquisar..."
                />
            </Header.GroupItens>
        </Header.RootContainer>
    )
}