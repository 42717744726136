import styled, { keyframes } from 'styled-components';

const SmoothAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 998;
  display: none;
  overflow: auto;

  filter: blur(4px);
  backdrop-filter: blur(4px);
`;

export const ModalContainer = styled.div`
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.white};
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border-radius: 16px;
  padding: 24px 18px;
  height: fit-content;

  &.delete {
    width: 396px;
  }

  &.edit {
    width: 396px;
  }

  &.generic-dream,
  &.gallery-modal {
    display: ${props => (props.showModal ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: space-between;
    background-color: ${props => props.theme.white};
    z-index: 1000;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    padding: 24px 18px;
    height: fit-content;
    width: max-content;
    animation: ${SmoothAnimation} 0.5s ease-out;
  }

  &.gallery-modal {
    scroll-behavior: smooth;
    overflow-y: scroll;
  }

  &.extract-modal {
    display: ${props => (props.showModal ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: space-between;
    background-color: ${props => props.theme.white};
    z-index: 1000;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    padding: 24px 18px;
    width: 409px;
    height: fit-content;
    overflow: auto;
    &.no-overflow {
      overflow: unset;
    }
  }
`;
