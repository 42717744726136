import React from 'react';
import { CardContainer } from './styles';
import { GenericLoading } from 'components/atoms/GenericLoading';
import { NavigationCard } from 'components/templates/NavigationCard';

export const GoalPanelHeader = ({
  financialPatrimony,
  materialPatrimony,
  seleable,
  investmentValue,
  loading,
}) => {
  if (loading) {
    return (
      <CardContainer>
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <GenericLoading
              className="goal-panel-navigation-card"
              key={index}
            />
          ))}
      </CardContainer>
    );
  }

  return (
    <CardContainer>
      <NavigationCard.Container className="goal-panel">
        <NavigationCard.Wrapper>
          <NavigationCard.GroupItens className='goal-panel'>
            <NavigationCard.Icon icon="growing-up" className="goal-panel" />
            <NavigationCard.Redirect to="/finances/patrimony/map" className="goal-panel" />
          </NavigationCard.GroupItens>
          <NavigationCard.Title
            title="Patrimônio Financeiro"
            className="goal-panel"
          />
        </NavigationCard.Wrapper>
        <NavigationCard.AmountOfMoney
          className="goal-panel"
          label="Valor disponível para selar"
          value={financialPatrimony}
        />
      </NavigationCard.Container>
      <NavigationCard.Container className="goal-panel">
        <NavigationCard.Wrapper>
          <NavigationCard.GroupItens className='goal-panel'>
            <NavigationCard.Icon icon="house" className="goal-panel" />
            <NavigationCard.Redirect to="/finances/patrimony/map" className="goal-panel" />
          </NavigationCard.GroupItens>
          <NavigationCard.Title
            title="Patrimônio Material"
            className="goal-panel"
          />
        </NavigationCard.Wrapper>
        <NavigationCard.AmountOfMoney
          className="goal-panel"
          label="Valor disponível para selar"
          value={materialPatrimony}
        />
      </NavigationCard.Container>
      <NavigationCard.Container className="goal-panel">
        <NavigationCard.Wrapper>
          <NavigationCard.GroupItens className='goal-panel'>
            <NavigationCard.Icon icon="dollar-sign" className="goal-panel" />
            <NavigationCard.Redirect to="/finances/budget?startIn=investments" className="goal-panel" />
          </NavigationCard.GroupItens>
          <NavigationCard.Title
            title="Meta de Investimento"
            className="goal-panel"
          />
        </NavigationCard.Wrapper>
        <NavigationCard.AmountOfMoney
          className="goal-panel"
          label="Aporte mensal"
          value={investmentValue}
        />
      </NavigationCard.Container>
      <NavigationCard.Container className="goal-panel sealed">
        <NavigationCard.Wrapper>
          <NavigationCard.GroupItens className='goal-panel'>
            <NavigationCard.Icon icon="sealed" className="goal-panel" />
          </NavigationCard.GroupItens>
          <NavigationCard.Title title="Valor selado" className="goal-panel" />
        </NavigationCard.Wrapper>
        <NavigationCard.AmountOfMoney
          className="goal-panel"
          label="Total"
          value={seleable}
        />
      </NavigationCard.Container>
    </CardContainer >
  );
};
