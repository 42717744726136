import React, { useState } from 'react';
import ReceiptCard from 'components/organisms/ReceiptCard';
import InvestmentCard from 'components/organisms/InvestmentCard';
import { BudgetTable } from 'components/organisms/BudgetTable';
import SpendingsCard from 'components/organisms/SpendingCard';
import DebtsCard from 'components/organisms/DebtsCard';
import DirecionalButton from 'components/organisms/Direcional';
import { PageContainer, CardContainer } from './styles';
import useBudgetManagement from 'hooks/useBudgetManagement';
import { useLocation } from 'react-router-dom';

const NAVIGATION_CARDS_TEMPLATE = {
  spendings: {
    estimated: "R$0",
    credit_card_bill_limit: "R$0",
  },
  debts: {
    estimated: "R$0",
  },
  investments: {
    estimated: "R$0",
  },
  receipts: {
    estimated: "R$0",
  }
}

const RECEIPTES_TEMPLATE = [
  {
    id: 0,
    user_id: 0,
    name: "Recebimento",
    type: "receipts",
    classification: "Recebimento",
    deleted_at: null,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    items: [
      {
        id: 0,
        user_id: 0,
        category_id: 0,
        name: "-",
        classification: "-",
        value: 0,
        when: 0,
        deleted_at: null,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        credit_card: false,
        monthly_value: 0
      }
    ],
    total: 0,
    percentage: 100
  }
]

const INVESTMENTS_TEMPLATE = {
  default_goals: {
    total: "R$0",
    percentage: "0%",
    data: [
      {
        name: "",
        value: "R$0",
        patrimonial_active_value: "R$0",
        financial_active_value: "R$0",
        final_financial_patrimony: "R$0",
        when: "0 Meses",
        allocation_strategy: "",
        monthly_investment_goal: "R$0",
        done_at: null,
        completed: false
      }
    ]
  },
  financial_independence_goals_detail: {
    total: "R$0",
    percentage: "0%",
    data: [
      {
        name: "Bpl | Base De Plena Liquidez",
        value: "R$0",
        patrimonial_active_value: "R$0",
        financial_active_value: "R$0",
        final_financial_patrimony: "R$0",
        when: null,
        allocation_strategy: "",
        monthly_investment_goal: "R$0",
        done_at: null,
        completed: false
      },
      {
        name: "Plf | Portifólio Da Liberdade",
        value: "R$0",
        patrimonial_active_value: "R$0",
        financial_active_value: "R$0",
        final_financial_patrimony: "R$0",
        when: null,
        allocation_strategy: "",
        monthly_investment_goal: "R$0",
        done_at: null,
        completed: false
      }
    ]
  }
}

const SPENDINGS_TEMPLATE = [
  {
    id: null,
    user_id: null,
    name: "Gastos",
    type: "spendings",
    classification: "",
    deleted_at: null,
    created_at: "",
    updated_at: "",
    items: [
      {
        id: null,
        user_id: null,
        category_id: null,
        name: "-",
        classification: "",
        value: null,
        when: null,
        deleted_at: null,
        created_at: "",
        updated_at: "",
        credit_card: false,
        monthly_value: null,
      },
    ],
    total: null,
    percentage: null,
  },
]

const DEBTS_TEMPLATE = {
  installments: {
    total: null,
    percentage: null,
    data: [
      {
        id: null,
        user_id: null,
        category_id: null,
        item_id: null,
        name: "",
        start: "",
        end: "",
        value: null,
        created_at: "",
        updated_at: "",
        current_installment: null,
        total_installments: null,
        remaining_value: null,
        item: {
          id: null,
          user_id: null,
          category_id: null,
          name: "",
          classification: "",
          value: null,
          when: null,
          deleted_at: null,
          created_at: "",
          updated_at: "",
          credit_card: false,
        },
        category: {
          id: null,
          user_id: null,
          name: "",
          type: "",
          classification: "",
          deleted_at: null,
          created_at: "",
          updated_at: ""
        },
        settled: false,
      }
    ]
  },
  patrimonial_passive: {
    total: null,
    percentage: null,
    data: [
      {
        id: null,
        user_id: null,
        name: "",
        type: "",
        institution: "",
        installment: null,
        end: "",
        rate: null,
        in_cash: null,
        settled_at: null,
        created_at: "",
        updated_at: "",
        remaining_installments: null,
        remaining_value: null,
        discount: null,
        settled: false,
      }
    ]
  },
  financial_passive: {
    total: null,
    percentage: null,
    data: [
      {
        id: null,
        user_id: null,
        name: "",
        type: "",
        institution: "",
        installment: null,
        end: "",
        rate: null,
        in_cash: null,
        settled_at: null,
        created_at: "",
        updated_at: "",
        remaining_installments: null,
        remaining_value: null,
        discount: null,
        settled: false,
      }
    ]
  }
}

export default function BudgetManagement() {
  const location = useLocation();
  const getStartPage = () => {
    const params = new URLSearchParams(location.search);
    const startIn = params.get('startIn');

    if (startIn === 'spendings') {
      return 'spendings'
    }

    if (startIn === 'debts') {
      return 'debts'
    }

    if (startIn === 'investments') {
      return 'investments'
    }

    return 'receipts'
  }


  const [currentPage, setCurrentPage] = useState(getStartPage());
  const [loadingCards, setLoadingCards] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const {
    getOverview,
  } = useBudgetManagement();

  const [navigationCard, setNavigationCard] = useState(NAVIGATION_CARDS_TEMPLATE);
  const [receipts, setReceipts] = useState(RECEIPTES_TEMPLATE);
  const [investments, setInvestments] = useState(INVESTMENTS_TEMPLATE);
  const [spendings, setSpendings] = useState(SPENDINGS_TEMPLATE);
  const [debts, setDebts] = useState(DEBTS_TEMPLATE);

  const limitCaracters = null

  const fillsVariables = (data) => {
    if (data.navigation_card) {
      setNavigationCard(data.navigation_card)
    }

    if (data.investments && data.investments !== INVESTMENTS_TEMPLATE) {
      setInvestments(data.investments)
    }

    if (data.receipts && data.receipts !== RECEIPTES_TEMPLATE) {
      setReceipts(data.receipts)
    }

    if (data.spendings && data.spendings !== SPENDINGS_TEMPLATE) {
      setSpendings(data.spendings)
    }

    if (data.debts && data.debts !== DEBTS_TEMPLATE) {
      setDebts(data.debts)
    }
  }

  const fillVariablesOnError = (page) => {
    switch (page) {
      case 'receipts':
        setReceipts(receipts === RECEIPTES_TEMPLATE ? RECEIPTES_TEMPLATE : receipts)
        break
      case 'investments':
        setInvestments(investments === INVESTMENTS_TEMPLATE ? INVESTMENTS_TEMPLATE : investments)
        break
      case 'spendings':
        setSpendings(spendings === SPENDINGS_TEMPLATE ? SPENDINGS_TEMPLATE : spendings)
        break
      case 'debts':
        setDebts(debts === DEBTS_TEMPLATE ? DEBTS_TEMPLATE : debts)
        break
      default:
        break
    }
  }

  const verifyPageData = (page) => {
    switch (page) {
      case 'receipts':
        return receipts === RECEIPTES_TEMPLATE
      case 'investments':
        return investments === INVESTMENTS_TEMPLATE
      case 'spendings':
        return spendings === SPENDINGS_TEMPLATE
      case 'debts':
        return debts === DEBTS_TEMPLATE
      default:
        return null
    }
  }

  const getOverviewValue = async (types = []) => {
    const response = await getOverview({
      formatedValue: true,
      types,
      limitCaracters
    })

    if (!response) {
      setNavigationCard(NAVIGATION_CARDS_TEMPLATE)
      setReceipts(RECEIPTES_TEMPLATE)
      setInvestments(INVESTMENTS_TEMPLATE)
      setSpendings(SPENDINGS_TEMPLATE)
      return
    }

    fillsVariables(response)
  }

  const reloadPageData = async () => {
    await getOverviewValue([currentPage, 'navigation_card']);
  }

  const onStart = async () => {
    try {
      setLoadingCards(true);
      setLoadingTable(true);

      const response = await getOverview({
        formatedValue: true,
        types: ['navigation_card', currentPage],
        limitCaracters
      })
      fillsVariables(response)
    } catch (error) {
      console.error(error)
      fillVariablesOnError(currentPage)
    } finally {
      setLoadingCards(false);
      setLoadingTable(false);
    }
  }

  const onChangePage = async (page) => {
    try {
      setCurrentPage(page)

      if (!verifyPageData(page)) {
        return;
      }

      setLoadingTable(true);
      const response = await getOverview({
        formatedValue: true,
        types: [page],
        limitCaracters
      })
      fillsVariables(response)
    } catch (error) {
      console.error(error)
      fillVariablesOnError(currentPage)
    } finally {
      setLoadingTable(false);
    }
  }

  React.useEffect(() => {
    onStart();
    // eslint-disable-next-line
  }, []);

  return (
    <PageContainer>
      <CardContainer>
        <ReceiptCard
          currentPage={currentPage}
          value={navigationCard.receipts.estimated}
          loading={loadingCards}
          onChangePage={onChangePage}
        />
        <InvestmentCard
          currentPage={currentPage}
          value={navigationCard.investments.estimated}
          loading={loadingCards}
          onChangePage={onChangePage}
        />
        <SpendingsCard
          currentPage={currentPage}
          loading={loadingCards}
          value={navigationCard.spendings.estimated}
          creditCardBillLimit={navigationCard.spendings.credit_card_bill_limit}
          onChangePage={onChangePage}
        />
        <DebtsCard
          currentPage={currentPage}
          onChangePage={onChangePage}
          value={navigationCard.debts.estimated}
          loading={loadingCards}
        />
      </CardContainer>
      <BudgetTable
        reloadPageData={reloadPageData}
        currentPage={currentPage}
        receipts={receipts}
        spendings={spendings}
        investments={investments}
        debts={debts}
        loading={loadingTable}
      />
      <DirecionalButton />
    </PageContainer>
  );
}
