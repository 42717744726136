import styled from "styled-components";

export const ImageIconContainer = styled.div`
    &.generic-dream {
        width: 22px;
        height: 22px;
    }
`;

export const StyledIcon = styled.svg`
    &.generic-dream {
        width: 22px;
        height: 22px;
        
        path {
            fill: ${({ theme }) => theme.grayPale};
        }
    }
`;