import { BarGraficTable } from 'components/atoms/BarGraficTable';
import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React, { useState } from 'react';
import { LegendTable } from '../LegendTable';
import { EditPEC } from 'components/molecules/SpendingModal/EditPEC';
import { generateClassName } from 'utils/text';

export const CommittedEventualTable = ({
  committedEventual,
  currentDate,
  setCurrentDate,
  updatePec,
  onChangeDate,
}) => {
  const columnOrder = [
    'item_name',
    'category_name',
    'when',
    'estimated',
    'accomplished',
    'status',
  ];

  const getClassByStatus = status => {
    const statusClasses = {
      ATRASADO: 'red',
      'A PAGAR': 'blue-lilac',
      PAGO: 'blue-midnight',
    };

    return statusClasses[status] || '';
  };

  const [showEdit, setShowEdit] = useState(false);

  const header = [
    'Item',
    'Categoria',
    'Vencimento',
    'Estimado',
    'Realizado',
    'Status',
  ];

  return (
    <Card.Container className="card-table">
      <Card.Container className="pot-table">
        <BarGraficTable
          valuePot={committedEventual?.total?.raw}
          pec={committedEventual?.accumulated_value?.value?.raw}
        />
        <LegendTable
          setShowModal={setShowEdit}
          valuePEC={committedEventual?.pec_prevision?.formatted}
          valueCommitted={
            committedEventual?.accumulated_value?.value?.formatted
          }
          total={committedEventual?.total?.formatted}
        />
        {showEdit && (
          <EditPEC
            showModal={showEdit}
            setShowModal={setShowEdit}
            theme="spendings"
            id={committedEventual?.accumulated_value?.id}
            value={committedEventual?.accumulated_value?.value?.formatted}
            onConfirm={async values => {
              const res = await updatePec(values);

              if (res) {
                setShowEdit(false);
              }
            }}
          />
        )}
      </Card.Container>
      <Card.Container className="table">
        <Table.Overflow>
          <Table.Title
            nameTable="Eventual Comprometido"
            type="years"
            className="pot-table year"
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            tooltipText="Não acontece todo mês, mas tem uma data específica no ano a pagar"
            onChangeDate={onChangeDate}
          />
          <Table.TableBasic className="pot-table">
            <Table.Thead>
              <Table.Tr className="receipt-table-title spendings">
                {header.map((item, index) => (
                  <Table.Th
                    className={generateClassName({
                      'budget-table-title': true,
                      'first-table-border checkbox': index === 0,
                      first: index === 0,
                    })}
                    first={index === 0}
                    key={index}
                  >
                    {item}
                  </Table.Th>
                ))}
                <Table.Th className="budget-table-title empty last-table-border" />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {committedEventual.items.map((column, rowIndex) => (
                <Table.Tr className="receipt-table-body" key={rowIndex}>
                  {columnOrder.map((item, index) => {
                    let currentItem = column[item];

                    if (item === 'status') {
                      return (
                        <Table.Td
                          className={`receipt-table-body status ${getClassByStatus(
                            currentItem
                          )}`}
                          key={index}
                        >
                          {currentItem}
                        </Table.Td>
                      );
                    }

                    return (
                      <Table.Td
                        className={generateClassName({
                          'receipt-table-body': true,
                          'first-table-border checkbox': index === 0,
                        })}
                        first={item === 'item'}
                        key={index}
                      >
                        {currentItem}
                      </Table.Td>
                    );
                  })}
                  <Table.Td></Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table.TableBasic>
        </Table.Overflow>
      </Card.Container>
    </Card.Container>
  );
};
