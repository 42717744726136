import { NavigationCardGroupItens } from "./NavigationCardGroupItens";
import { NavigationCardIcon } from "./NavigationCardIcon";
import { NavigationCardRedirect } from "./NavigationCardRedirect";
import { NavigationCardTitle } from "./NavigationCardTitle";
import { CardContainer } from "./RootContainer";
import { NavigationCardAmountOfMoney } from "./NavigationCardAmountOfMoney";
import { NavigationCardDoughnutGrafic } from "./NavigationCardDoughnutGrafic";
import { NavigationCardText } from "./NavigationCardText";
import { NavigationUpAndDownSelector } from "./NavigationUpAndDownSelector";
import { NavigationCardWrapper } from "./NavigationCardWrapper";

export const NavigationCard = {
    Container: CardContainer,
    Icon: NavigationCardIcon,
    GroupItens: NavigationCardGroupItens,
    Redirect: NavigationCardRedirect,
    Title: NavigationCardTitle,
    AmountOfMoney: NavigationCardAmountOfMoney,
    DoughnutGrafic: NavigationCardDoughnutGrafic,
    Text: NavigationCardText,
    UpAndDownSelector: NavigationUpAndDownSelector,
    Wrapper: NavigationCardWrapper
}