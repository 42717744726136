import React from 'react';
import { Label } from 'components/atoms/Label';
import { Input } from 'components/atoms/Input';
import { Span } from 'components/atoms/Span';
import { getOnlyNumbers } from 'utils/numbers';

export const ModalPercentageField = ({
    className,
    getInputValue,
    placeholder,
    label,
    defaultValue = 0,
}) => {
    const handleChange = (e) => {
        const { value } = e.target;
        const numericValue = getOnlyNumbers(value) / 100;
        getInputValue(numericValue || 0);
    };

    const formatValue = (value) => {
        return `${(value || 0).toFixed(2)}% a.m`;
    };

    return (
        <Label className={className}>
            <Span className={className}>{label}</Span>
            <Input
                className={className}
                value={formatValue(defaultValue)}
                onChange={handleChange}
                placeholder={placeholder}
            />
        </Label>
    );
};
