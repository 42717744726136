import React from 'react';
import { ModalFooter } from './styles';
import { Button } from 'components/atoms/Button';
import { GenericLoading } from 'components/atoms/GenericLoading';

export const SimpleModalFooter = ({
  className,
  onCancel,
  onConfirm,
  onExclude,
  cancelButtonText = 'Cancelar',
  confirmButtonText = 'Concluído',
  loading = false,
  loadingClassname = 'loading',
}) => {
  if (loading) {
    return (
      <ModalFooter>
        <GenericLoading className={loadingClassname + ' first'} />
        <GenericLoading className={loadingClassname + ' second'} />
      </ModalFooter>
    );
  }

  return (
    <ModalFooter>
      <Button className={`${className} cancel`} onClick={onConfirm}>
        {confirmButtonText}
      </Button>
      {onExclude && (
        <Button className={`${className} confirm`} onClick={onExclude}>
          Excluir
        </Button>
      )}
      {onCancel && (
        <Button className={`${className} confirm`} onClick={onCancel}>
          {cancelButtonText}
        </Button>
      )}
    </ModalFooter>
  );
};
