import React from 'react';
import { Label } from 'components/atoms/Label';
import { Input } from 'components/atoms/Input';
import { Span } from 'components/atoms/Span';
import { formatPercentage } from 'utils/numbers';

export const ModalInputPercentage = ({
  className,
  getInputValue,
  getRawInputValue,
  placeholder,
  label,
  defaultValue = '',
}) => {
  const percentageSymbol = '% a.m';

  const [inputValue, setInputValue] = React.useState(
    formatPercentage(defaultValue)
  );

  React.useEffect(() => {
    const numericValue = parseFloat(
      inputValue.replace(percentageSymbol, '').trim()
    );
    getInputValue(inputValue);
    if (getRawInputValue) {
      getRawInputValue(isNaN(numericValue) ? 0 : numericValue);
    }
  }, [inputValue, getInputValue, getRawInputValue]);

  const handleChange = event => {
    event.persist();

    const rawValue = event.target.value;

    const cursorPosition = event.target.selectionStart;
    const currentValue = rawValue.replace(/[^\d,]/g, '').replace(',', '.');
    const formattedValue = formatPercentage(currentValue);

    setInputValue(formattedValue);
    setTimeout(() => {
      event.target.selectionStart = cursorPosition;
      event.target.selectionEnd = cursorPosition;
    }, 0);
  };

  return (
    <Label className={className}>
      <Span className={className}>{label}</Span>
      <Input
        className={className}
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </Label>
  );
};
