import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { IconContainer, InputContainer } from './styles';
import { FiSearch } from 'react-icons/fi';
import { Input } from 'components/atoms/Input';

export const SearchBox = ({
  text = '',
  setText = () => null,
  placeholder = '',
}) => {
  const [isInputVisible, setIsInputVisible] = useState(text.length);
  const inputRef = useRef(null);

  const handleClick = () => {
    setIsInputVisible(true);
  };

  const handleBlur = useCallback(
    e => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setIsInputVisible(false);
      }
    },
    [inputRef]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);
    return () => {
      document.removeEventListener('mousedown', handleBlur);
    };
  }, [handleBlur]);

  const renderedInput = useMemo(
    () => (
      <InputContainer className="main">
        <Input
          type="text"
          value={text}
          onChange={e => setText(e.target.value)}
          placeholder={placeholder || 'Pesquisar...'}
          className="extract-menu-header"
        />
        <FiSearch className="detailed-extract-search-icon-right" />
      </InputContainer>
    ),
    [text, setText, placeholder]
  );

  return (
    <div ref={inputRef}>
      {isInputVisible ? (
        renderedInput
      ) : (
        <IconContainer
          className="detailed-extract-search-icon"
          onClick={handleClick}
        >
          <FiSearch className="detailed-extract-search-icon" />
        </IconContainer>
      )}
    </div>
  );
};
