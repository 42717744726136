import React from 'react';
import { LoadingTable } from 'components/templates/Table/LoadingTable';
import { TableReport } from './Table';
import { EditSpendingItemModal } from 'components/molecules/BudgetModal/EditSpendingItemModal';
import toast from 'react-hot-toast';

export const ReportSpendingTable = ({
  currentPage,
  data,
  loading,
  fetchItemValue,
  selectedItem,
  updateItem,
  deleteItem,
  reloadPage,
}) => {
  const [showModal, setShowModal] = React.useState(false);

  const handleEditClick = item => {
    try {
      setShowModal(true);
      fetchItemValue(item?.item_id);
    } catch (error) {
      toast.error('Nao foi possivel carregar o item da modal');
    }
  };

  const editItem = async (updatedItem) => {
    try {
      await updateItem(selectedItem?.id, updatedItem);
      await reloadPage();
      toast.success('Item atualizado com sucesso!');
      setShowModal(false);
    } catch (error) {
      toast.error('Não foi possível atualizar o item');
    }
  };

  const handleDeleteItem = async () => {
    try {
      await deleteItem(selectedItem?.id);
      await reloadPage();
      setShowModal(false);
      toast.success('Item deletado com sucesso!');
    } catch (error) {
      toast.error('Não foi 가능 deletar o item');
    }
  };


  const pages = {
    spendings: (
      <>
        {data.map((item, index) => (
          <TableReport
            data={item}
            type="spendings"
            title={item?.category_name || ''}
            key={Math.random()}
            fetchItemValue={fetchItemValue}
            selectedItem={selectedItem}
            showModal={showModal}
            setShowModal={setShowModal}
            handleEditClick={handleEditClick}
          />
        ))}
      </>
    ),
    receipt: (
      <>
        {data.map((item, index) => (
          <TableReport
            data={item}
            type="receipts"
            title={item?.category_name || ''}
            key={Math.random()}
            fetchItemValue={fetchItemValue}
            selectedItem={selectedItem}
            showModal={showModal}
            setShowModal={setShowModal}
            handleEditClick={handleEditClick}
          />
        ))}
      </>
    ),
    investment: (
      <>
        {data.map((item, index) => (
          <TableReport
            data={item}
            type="investments"
            title={item?.category_name || ''}
            key={Math.random()}
            canEdit={false}
          />
        ))}
      </>
    ),
  };

  if (loading) {
    return <LoadingTable />;
  }
  return (
    <>
      {
        showModal && (
          <EditSpendingItemModal
            showModal={showModal}
            setShowModal={setShowModal}
            selectedItem={selectedItem}
            title="Editar recebimento"
            onCancel={editItem}
            onConfirm={handleDeleteItem}
            icon="edit"
            theme="edit"
            classificationOptions={'teste 1'}
          />
        )
      }
      {pages[currentPage] || <></>}
    </>
  );
};
