import styled from "styled-components";

export const InputImageContainer = styled.div`
  &.generic-dream {
    width: 357px;
    height: 104px;
    border: 1px solid ${props => props.theme.grayShadow};
    margin-top: 32px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: ${props =>
    props.backgroundImage
      ? `linear-gradient(
            180deg,
            rgba(12, 5, 63, 0) -2.75%,
            rgba(12, 5, 63, 0.5525) 50.46%,
            rgba(12, 5, 63, 0.85) 99.89%
          ), url(${props.backgroundImage})`
      : 'none'
  };
    background-size: cover;
    background-position: center;
  }
`;