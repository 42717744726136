import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';
import { useUpdateActivePatrimony } from 'hooks/useActivePatrimony';
export const EditRealEstateList = ({
  showModal = false,
  setShowModal = () => {},
  selectedItem = null,
  selectedCategoryKey,
  onUpdate = () => {},
  onCancel = () => {},
  theme = 'receipts',
}) => {
  const { updateActivePatrimony } = useUpdateActivePatrimony();
  const [name, setName] = useState(selectedItem?.name);
  const [value, setValue] = useState(selectedItem?.value);
  const [receipt, setReceipt] = useState(selectedItem?.receipt);
  const [rent, setRent] = useState(selectedItem?.rent);
  const [expense, setExpense] = useState(selectedItem?.expense);
  const [profitability, setProfitability] = useState(
    selectedItem?.profitability
  );
  const [recom, setRecom] = useState(selectedItem?.recom);

  const confirm = () => {
    setShowModal(false);
    updateActivePatrimony(selectedItem.id, {
      name,
      market_value: selectedItem.market_value,
      rent: selectedItem.rent,
      monthly_expenses: selectedItem.monthly_expenses,
      net_revenue: selectedItem.net_revenue,
      profitability: selectedItem.profitability,
      recommendation: selectedItem.recommendation,
    })
    // onUpdate({
    //   name,
    //   market_value: selectedItem.market_value,
    //   rent: selectedItem.rent,
    //   monthly_expenses: selectedItem.monthly_expenses,
    //   net_revenue: selectedItem.net_revenue,
    //   profitability: selectedItem.profitability,
    //   recommendation: selectedItem.recommendation,
    // });
  };
  const cancel = () => {
    let currentCategory = JSON.parse(localStorage.getItem(selectedCategoryKey));
    currentCategory.name = name;
    localStorage.setItem(selectedCategoryKey, JSON.stringify(currentCategory));
    setShowModal(false);
    onCancel();
  };

  const options = [
    { label: 'Vender', value: 'vender' },
    { label: 'Alugar', value: 'Alugar' },
    { label: 'Manter', value: 'Manter' },
  ];

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar Imóvel"
          onClose={() => setShowModal(false)}
          type="edit"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="generic-dream-modal"
          label="Nome do imóvel"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.GroupInputs show={true} className="generic-dream-modal">
          <Modal.InputMoney
            className="generic-dream-modal first half "
            label="Valor de mercado "
            getInputValue={setValue}
            defaultValue={value}
          />
          <Modal.InputMoney
            className="generic-dream-modal first half last  "
            label="Receita líquida"
            getInputValue={setReceipt}
            defaultValue={receipt}
          />
        </Modal.GroupInputs>
        <Modal.GroupInputs show={true} className="generic-dream-modal">
          <Modal.InputMoney
            className="generic-dream-modal first half "
            label="Renda (aluguel) "
            getInputValue={setRent}
            defaultValue={rent}
          />
          <Modal.InputMoney
            className="generic-dream-modal first half last  "
            label="Despesas mensais"
            getInputValue={setExpense}
            defaultValue={expense}
          />
        </Modal.GroupInputs>
        <Modal.GroupInputs show={true} className="generic-dream-modal">
          <Modal.InputMoney
            className="generic-dream-modal first half "
            label="Rentabilidade "
            getInputValue={setProfitability}
            defaultValue={profitability}
          />
          <Modal.Dropdown
            label="Recomendação"
            className="generic-dream-modal after half last second-section"
            selectOptions={options}
            getDropdownValue={setRecom}
            placeholderSelect={recom}
          />
        </Modal.GroupInputs>
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        onCancel={cancel}
        className={`simple-modal ${theme}`}
        confirmButtonText="Confirmar"
        cancelButtonText="Excluir imóvel"
      />
    </Modal.Container>
  );
};
