import { useState, useEffect, useCallback } from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const usePatrimony = () => {
  const { id: userId } = useSelector(state => state.user.profile);

  const [activePatrimonies, setActivePatrimonies] = useState([]);
  const [passivePatrimonies, setPassivePatrimonies] = useState([]);
  const [overview, setOverview] = useState([]);

  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    if (!userId) {
      toast.error('Usuário não encontrado.');
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const [
        { data: overview },
        { data: activePatrimonies },
        { data: passivePatrimonies },
      ] = await Promise.all([
        api.get(`/patrimony/v2/overview/${userId}`, {
          params: { formated_value: true },
        }),
        api.get(`/users/${userId}/v2/active_patrimonies`),
        api.get(`/users/${userId}/v2/passive_patrimony`),
      ]);

      setOverview(overview);
      setActivePatrimonies(activePatrimonies);
      setPassivePatrimonies(passivePatrimonies.passivePatrimonies);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Ocorreu um erro ao buscar os dados.');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const createActive = async data => {
    setLoading(true);
    try {
      await api.post(`users/${userId}/active_patrimonies`, data);
      toast.success('Ativo criado com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao criar o ativo.');
    } finally {
      setLoading(false);
    }
  };
  const createPassive = async data => {
    setLoading(true);
    try {
      await api.post(`users/${userId}/passive_patrimony`, data);
      toast.success('Passivo criado com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao criar o passivo.');
    } finally {
      setLoading(false);
    }
  };

  const updateActive = async (id, updatedData) => {
    try {
      await api.put(`active_patrimonies/${id}`, updatedData);
      toast.success('Ativo atualizado com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar o ativo.');
    } finally {
      setLoading(false);
    }
  };
  const updatePassive = async (id, updatedData) => {
    setLoading(true);
    try {
      await api.put(`passive_patrimony/${id}`, updatedData);
      toast.success('Passivo atualizado com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar o passivo.');
    } finally {
      setLoading(false);
    }
  };
  const deletePassive = async id => {
    setLoading(true);
    try {
      await api.delete(`passive_patrimony/${id}`);
      toast.success('Passivo excluído com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao excluir o passivo.');
    } finally {
      setLoading(false);
    }
  };
  const deleteActive = async id => {
    setLoading(true);
    try {
      await api.delete(`active_patrimonies/${id}`);
      toast.success('Atvio excluído com sucesso!');
    } catch (error) {
      toast.error('Ocorreu um erro ao excluir o ativo.');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    activePatrimonies,
    passivePatrimonies,
    overview,
    createActive,
    createPassive,
    updateActive,
    updatePassive,
    deleteActive,
    deletePassive,
    fetchData,
    setActivePatrimonies,
    setPassivePatrimonies,
  };
};

export default usePatrimony;
