import React from 'react';
import { EatingOutCategoryIcon } from 'components/atoms/icons/EatingOutCategoryIcon';
import { HousingCategoryIcon } from 'components/atoms/icons/HousingCategoryIcon';
import { LifeAndHealthCategoryIcon } from 'components/atoms/icons/LifeAndHealthCategoryIcon';
import { LeisureCategoryIcon } from 'components/atoms/icons/LeisureCategoryIcon';
import { LegalEntityCategoryIcon } from 'components/atoms/icons/LegalEntityCategoryIcon';
import { PersonalCategoryIcon } from 'components/atoms/icons/PersonalCategoryIcon';
import { TransportCategoryIcon } from 'components/atoms/icons/TransportCategoryIcon';
import { InvestmentBarChartCategoryIcon } from 'components/atoms/icons/InvestmentBarChartCategoryIcon';
import { WalletCategoryIcon } from 'components/atoms/icons/WalletCategoryIcon';
export const getCategoryIconByClassification = (
  classification,
  name,
  hasItem
) => {
  const normalizedClassification = classification.toLowerCase();
  const normalizedName = name.toLowerCase();

  const getClassName = () => (hasItem ? 'extract-title item' : 'extract-title');

  if (
    normalizedClassification.includes('alimentação') ||
    normalizedName.includes('alimentação')
  ) {
    return <EatingOutCategoryIcon className={getClassName()} />;
  }
  if (
    normalizedClassification.includes('moradia') ||
    normalizedName.includes('moradia')
  ) {
    return <HousingCategoryIcon className={getClassName()} />;
  }
  if (
    normalizedClassification.includes('vida') ||
    normalizedClassification.includes('saúde') ||
    normalizedName.includes('vida') ||
    normalizedName.includes('saúde')
  ) {
    return <LifeAndHealthCategoryIcon className={getClassName()} />;
  }
  if (
    normalizedClassification.includes('lazer') ||
    normalizedName.includes('lazer')
  ) {
    return <LeisureCategoryIcon className={getClassName()} />;
  }
  if (
    normalizedClassification.includes('pessoa jurídica') ||
    normalizedName.includes('pessoa jurídica')
  ) {
    return <LegalEntityCategoryIcon className={getClassName()} />;
  }
  if (
    normalizedClassification.includes('pessoal') ||
    normalizedName.includes('pessoal')
  ) {
    return <PersonalCategoryIcon className={getClassName()} />;
  }
  if (
    normalizedClassification.includes('transporte') ||
    normalizedName.includes('transporte')
  ) {
    return <TransportCategoryIcon className={getClassName()} />;
  }
  if (
    normalizedClassification.includes('investimento') ||
    normalizedName.includes('investimento')
  ) {
    return <InvestmentBarChartCategoryIcon className={getClassName()} />;
  }
  if (
    normalizedClassification.includes('carteira') ||
    normalizedName.includes('carteira')
  ) {
    return <WalletCategoryIcon className={getClassName()} />;
  }

  return <WalletCategoryIcon className={getClassName()} />;
};
