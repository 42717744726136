import React, { useEffect, useState } from 'react';
import { Modal } from 'components/templates/Modal';
import { getMonthNumber, getMonths, monthNumberToName } from 'utils/date';
import { getItemsClassificationOptions } from 'utils/data';

export const EditSpendingItemModal = ({
  showModal = false,
  setShowModal = () => { },
  title = '',
  icon = 'edit',
  onCancel = () => { },
  onConfirm = () => { },
  theme,
  classificationOptions = [],
  selectedItem,
}) => {
  const [name, setName] = useState(selectedItem?.name || '');
  const [classification, setClassification] = useState(selectedItem?.classification || '');
  const [maturity, setMaturity] = useState(selectedItem?.when || '');
  const [value, setValue] = useState(selectedItem?.value || '');

  useEffect(() => {
    console.log('vou rodar demais')
    setName(selectedItem?.name || '');
    setClassification(selectedItem?.classification || '');
    setMaturity(selectedItem?.when || '');
    setValue(selectedItem?.value || '');
  }, [selectedItem]);

  const additionalSettings = {
    'mensal comprometido': (
      <>
        <Modal.InputNumber
          className="simple-modal after"
          label="Dia do mês"
          getInputValue={setMaturity}
          defaultValue={maturity}
          dayOfMonth
        />
        <Modal.InputCurrency
          className="simple-modal after only-it"
          label="Valor mensal"
          getInputValue={setValue}
          defaultValue={value}
        />
      </>
    ),
    'mensal flexível': (
      <Modal.InputCurrency
        className="simple-modal after only-it"
        label="Valor mensal"
        getInputValue={setValue}
        defaultValue={value}
      />
    ),
    'eventual comprometido': (
      <>
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={getMonths()}
          getDropdownValue={setMaturity}
          label="Mês do ano"
          placeholderSelect={typeof maturity === 'number' ? monthNumberToName(maturity) : maturity}
        />
        <Modal.InputCurrency
          className="simple-modal after"
          label="Valor anual"
          getInputValue={setValue}
          defaultValue={value}
        />
      </>
    ),
    'eventual flexível': (
      <Modal.InputCurrency
        className="simple-modal after only-it"
        label="Valor anual"
        getInputValue={setValue}
        defaultValue={value}
      />
    )
  }

  const returnAdditionalSettings = () => {
    let base = String(classification).toLocaleLowerCase();
    return additionalSettings[base] || <></>;
  };

  const cancel = () => {

    let when = classification === 'Eventual Comprometido' ? getMonthNumber(maturity) : Number(maturity);

    if (['Eventual Comprometido', 'Eventual Flexível', 'Mensal Flexível'].includes(classification)) {
      when = null;
    }

    onCancel({
      name,
      classification,
      when,
      value: value || 0
    });
  }

  const confirm = () => {
    onConfirm();
  }

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title={title}
          onClose={() => setShowModal(false)}
          type={icon}
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputText
          className="simple-modal"
          label="Nome do item"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.Dropdown
          className="simple-modal after"
          selectOptions={getItemsClassificationOptions()}
          getDropdownValue={setClassification}
          label="Classificação"
          placeholderSelect={classification}
        />
        {returnAdditionalSettings()}
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onCancel={() => cancel()}
        onConfirm={() => confirm()}
        className={`simple-modal ${theme}`}
        confirmButtonText="Excluir item"
        cancelButtonText="Concluído"
      />
    </Modal.Container>
  );
};
