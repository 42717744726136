import styled from 'styled-components';

export const StyledCloseModalIcon = styled.svg`
  &.simple-modal {
    width: 11px;
    height: 11px;
    cursor: pointer;
    margin-left: auto;

    &.debts, &.delete {
      path {
        stroke: ${({ theme }) => theme.redMedium};
      }
    }

    &.receipt {
      path {
        fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
        stroke: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
      }
    }

    &.spendings {
      path {
        fill: ${({ theme }) => theme.blueSky};
        stroke: ${({ theme }) => theme.blueSky};
      }
    }

    &.edit {
      fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
      path {
        stroke: ${({ theme }) => theme.blueMidnight};
      }
    }
    &.patrimony {
      fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
      path {
        stroke: ${({ theme }) => theme.blueMidnight};
      }
    }
  }

  &.extract-modal {
    width: 11px;
    height: 11px;
    cursor: pointer;
    fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
    margin-left: auto;

    path {
      stroke: ${({ theme }) => theme.blueMidnight};
    }
  }

  &.generic-dream {
    width: 11px;
    height: 11px;
    cursor: pointer;
    margin-left: auto;

    path {
      stroke: ${({ theme }) => theme.blueMidnight};
    }
  }
`;
