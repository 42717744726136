import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineMail } from 'react-icons/md';
import { toast } from 'react-toastify';
import newApi from 'services/newApi';
import LogoImg from 'assets/logo-login.png';
import {
  WrapperGray,
  Wrapper,
  Container,
  ContainerImage,
  HeaderGroup,
  BottomGroup,
  Form,
  Logo,
  InputGroup,
} from './styles';
import ResetPassoword from '../ResetPassoword';
import { Button } from 'components/atoms/Button';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [sended, setSended] = useState(false);
  const [code, setCode] = useState('');
  const [verify, setVerify] = useState(false);
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await newApi.post('Usuario/recuperar-slim', {
        email,
        // redirect_url: `${process.env.REACT_APP_FRONTEND_URL ||
        //   'http://localhost:3000'}/reset_password`,
      });

      setSended(!sended);
      setLoading(false);
    } catch (err) {
      toast.error('Email não encontrado');
    }
  }
  async function handleSubmitCode(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await newApi.post('Usuario/valida-slim', {
        email,
        codigoRecuperacao: code,
        // redirect_url: `${process.env.REACT_APP_FRONTEND_URL ||
        //   'http://localhost:3000'}/reset_password`,
      });

      if (data.sucesso) {
        setVerify(true);
      }

      setLoading(false);
    } catch (err) {
      setVerify(false);
      setLoading(false);
      toast.error('código inválido');
    }
  }

  return (
    <WrapperGray>
      <Wrapper>
        {sended && !verify && (
          <>
            <Container>
              <Logo src={LogoImg} alt="Yuno On" className="imgLogo" />
              <Form onSubmit={handleSubmitCode}>
                <div className="control-group">
                  <label className="control-label" htmlFor="inputEmail">
                    Código
                  </label>
                  <InputGroup className="bordered">
                    <div className="button-lock">
                      <MdOutlineMail size={25} />
                    </div>
                    <input
                      type="number"
                      name="code"
                      id="inputCode"
                      placeholder="Digite o código..."
                      value={code}
                      onChange={e => setCode(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <Button
                  className="m-8 inter"
                  type="submit"
                  color="blueDarker"
                  loading={loading}
                >
                  Enviar
                </Button>
                <div className="centered m-8">
                  <Link to="/">Voltar ao Login</Link>
                </div>
              </Form>
            </Container>
            <ContainerImage>
              <HeaderGroup>
                <div className="watermark" />
                <h1>
                  Planejamento Financeiro
                  <br />
                  para quem quer curtir o presente e desfrutar do futuro.
                </h1>
              </HeaderGroup>
              <BottomGroup>
                <h2>Viver é o melhor investimento!</h2>
              </BottomGroup>
            </ContainerImage>
          </>
        )}
        {!sended ? (
          <>
            <Container>
              <Logo src={LogoImg} alt="Yuno On" className="imgLogo" />
              <div className="text-container">
                <h1>Esqueceu sua senha?</h1>
                <span>
                  Digite o endereço de e-mail que você usou quando se cadastrou
                  e enviaremos instruções para redefinir sua senha.
                </span>
              </div>
              <Form onSubmit={handleSubmit}>
                <div className="control-group">
                  <label className="control-label" htmlFor="inputEmail">
                    E-mail
                  </label>
                  <InputGroup className="bordered">
                    <div className="button-lock">
                      <MdOutlineMail size={25} />
                    </div>
                    <input
                      type="email"
                      name="email"
                      id="inputEmail"
                      placeholder="Digite seu e-mail"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <Button
                  className="m-8 inter"
                  type="submit"
                  color="blueDarker"
                  loading={loading}
                >
                  Enviar
                </Button>
                <div className="centered m-8">
                  <Link to="/">Voltar ao Login</Link>
                </div>
              </Form>
            </Container>
            <ContainerImage>
              <HeaderGroup>
                <div className="watermark" />
                <h1>
                  Planejamento Financeiro
                  <br />
                  para quem quer curtir o presente e desfrutar do futuro.
                </h1>
              </HeaderGroup>
              <BottomGroup>
                <h2>Viver é o melhor investimento!</h2>
              </BottomGroup>
            </ContainerImage>
          </>
        ) : verify && sended ? (
          <>
            <ResetPassoword email={email} code={code} />
          </>
        ) : (
          <></>
          // <Link to="/">
          //   <Button color="primary">Voltar ao Login</Button>
          // </Link>
        )}
      </Wrapper>
    </WrapperGray>
  );
}
