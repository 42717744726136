import styled from 'styled-components';
import { VerticalScrollbarStyles } from 'styles/components/VerticalScrollbarStyles';

export const PageContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow: auto;
`;

export const Container = styled.div`
  margin-left: 5%;
  margin-right: 32px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  margin-top: 27px;
  overflow: auto;

  &.extract {
    width: 59.5%;

    @media (max-width: 1250px) {
      min-width: 811px;
      width: 100%;
    }
  }

  &.detailed-extract {
    @media (max-width: 1024px) {
      min-width: 811px;
      width: 100%;
      margin-left: 0;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 14.4px;
  }
`;

export const ExtractWrapper = styled.div`
  background-color: ${props => props.theme.white};
  width: 100%;
  height: 77.46vh;
  border-radius: 16px;
  margin-top: 31px;
  margin-bottom: 52px;
  padding: 2.42%;
  padding-left: 2.52%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding-bottom: 24px;

  ${VerticalScrollbarStyles}

  @media (max-width: 1440px) {
    padding: 2.42%;
    padding-left: 2.52%;

    gap: 16px;
    margin-bottom: 22.9px;
    padding-bottom: 18.1px;
  }
`;

export const CategoryContainer = styled.div`
  &.last {
    margin-bottom: 24px;
  }
`;
