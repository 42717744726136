import React from 'react';
import { Label } from 'components/atoms/Label';
import { Input } from 'components/atoms/Input';
import { Span } from 'components/atoms/Span';
import { formatInputMoney, getOnlyNumbers } from 'utils/numbers';

export const ModalInputCurrency = ({
    className,
    getInputValue,
    placeholder,
    label,
    defaultValue = 0,
    disabled = false,
}) => {

    const handleChange = e => {
        const { value } = e.target;
        const formattedValue = getOnlyNumbers(value) / 100;
        getInputValue(formattedValue || 0);
    };

    return (
        <Label className={className}>
            <Span className={className}>{label}</Span>
            <Input
                className={`${className} ${disabled ? 'disabled' : ''}`}
                value={formatInputMoney(Number(defaultValue).toFixed(2))}
                onChange={handleChange}
                placeholder={placeholder}
                disabled={disabled}
            />
        </Label>
    );
};

