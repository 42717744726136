import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const Th = styled.th`
  &.budget-table-title {
    color: ${({ theme }) => theme.white};
    border: none;
    text-align: ${props => (props.first ? 'left' : 'center')};
    font-size: 12px;
    height: 44px;
    padding: 12px 16px;
  }

  &.budget-table-title.first {
    text-align: left;
  }

  &.budget-table-title.receipts {
    background-color: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
  }

  &.budget-table-title.debts {
    background-color: ${({ theme }) => theme.redBloodWithSlowOpacity};
  }

  &.budget-table-title.investments {
    background-color: ${({ theme }) => theme.greenDark};
  }

  &.budget-table-title.spendings {
    background-color: ${({ theme }) => theme.blueSky};
  }

  &.budget-table-title.checkbox {
    width: min-content;
    height: min-content;
    text-align: left;
  }

  &.budget-table-title.empyt {
    width: 45px;
  }

  &.first-table-border {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &.last-table-border {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 50px;

    &.final {
      width: 0;
    }
  }
  &.debts {
    width: min-content;
    height: min-content;
    text-align: center;
  }

  &.loading {
    height: 58px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 4px;
  }
`;
